<template>
  <div>
    <v-card tile>
      <v-list dense dark color="secondary" class="py-0">
        <v-list-item
          class="py-1"
          :href="$route.params.charityId ? '/my/' + $route.params.charityId + '/applications' : '/my/applications'"
          ripple
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase font-weight-bold">{{
              $t("admin.menu.back_to_apps")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-toolbar dark color="primary" flat class="mt-5" dense v-if="!!information">
      <v-toolbar-title class="subtitle-1 font-weight-regular">{{ information }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="url">
        <v-btn
          v-if="$route.params.formId"
          icon
          :href="url"
          target="_blank"
          :title="$t('admin.menu.go_to_donation_from')"
        >
          <v-icon>mdi-launch</v-icon>
        </v-btn>
        <v-btn v-else icon :href="url" target="_blank" :title="$t('admin.menu.go_to_landing')">
          <v-icon>mdi-launch</v-icon>
        </v-btn>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      event: "",
      url: "",
      donationForm: "",
    };
  },
  computed: {
    information: function () {
      let result;

      if (this.$route.params.eventId) result = this.event.name;
      else if (this.$route.params.formId) result = this.donationForm.name;
      else if (this.$route.matched[0].path == "/settings") result = this.$t("admin.menu.applications.settings");
      else if (this.$route.matched[0].path == "/personal") result = this.$t("admin.menu.applications.personal");
      else if (this.$route.matched[0].path == "/marketing") result = this.$t("admin.menu.applications.marketing");

      return result;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      if (this.$route.params.eventId) {
        this.event = await this.$axios.get(`/common/event/${this.$route.params.eventId}`);
        let result = await this.$axios.get(
          `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/links`
        );
        if (this.event.idOwningCharity == this.$store.state.charity.id && this.event.fundraisingEvent) {
          this.url = result.eventPageUrl;
        } else {
          this.url = result.communityPageUrl;
        }
      } else if (this.$route.params.formId) {
        this.donationForm = await this.$axios.get(
          `/donationForms/${this.$store.state.charity.id}/form/${this.$route.params.formId}`
        );
        let result = await this.$axios.get(
          `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/link`
        );
        this.url = result.link;
      } else if (this.$route.matched[0].path == "/personal" || this.$route.matched[0].path == "/settings") {
        let result = await this.$axios.get(`/personal/${this.$store.state.charity.id}/links`);
        this.url = result.communityPageUrl;
      }
    },
  },
};
</script>
