<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.personalFundraising.settings.contentPage.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.personalFundraising.settings.contentPage.titleDescription") }}
        </v-card-subtitle>
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <quill-editor
                  v-model="charityProfile.content"
                  :options="{
                    modules: {
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                        [{ align: ['', 'center', 'right'] }],
                        ['link'],
                        ['clean'],
                      ],
                    },
                    placeholder: $t('admin.personalFundraising.settings.contentPage.description'),
                  }"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn raised color="primary" v-on:click="save()">{{ $t("admin.users.edit.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      charityProfile: {},
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let result = await this.$axios.get(`/admin/${this.$store.state.charity.id}/charityProfile`);
      this.charityProfile = result.charityProfile;
    },
    async save() {
      await this.$axios.put(`/admin/${this.$store.state.charity.id}/charityProfile`, this.charityProfile);
      this.$store.commit("SUCCESS", this.$t("admin.personalFundraising.settings.contentPage.msg_saved"));
    },
  },
};
</script>

<style></style>
