<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.donationForm.settings.title") }}
        </v-card-title>
        <v-form @submit.prevent="save" ref="createForm" v-model="valid">
          <v-container fluid class="pt-0">
            <v-card-text>
              <v-text-field
                v-model="data.name"
                :label="$t('admin.donationForm.settings.name')"
                :rules="[$v.required(), $v.maxLength(255)]"
                required
              ></v-text-field>
            </v-card-text>
            <v-toolbar color="grey darken-3" dark dense text class="mt-5">
              <v-icon>mdi-text-subject</v-icon>
              <v-toolbar-title class="ml-3">{{ $t("admin.donationForm.settings.contentSettings") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="data.title"
                :label="$t('admin.donationForm.settings.formTitle')"
                :rules="[$v.maxLength(255)]"
                required
              ></v-text-field>
              <div class="text-caption grey--text text--darken-2 my-1">
                {{ $t("admin.donationForm.settings.message") }}
              </div>
              <quill-editor
                v-model="data.message"
                :options="{
                  modules: {
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                      [{ align: ['', 'center', 'right'] }],
                      ['link'],
                      ['clean'],
                    ],
                  },
                  placeholder: $t('admin.donationForm.settings.messagePlaceholder'),
                }"
              />
              <div class="text-caption grey--text text--darken-2 my-1">
                {{ $t("admin.donationForm.settings.messageHint") }}
              </div>
            </v-card-text>
            <v-toolbar color="grey darken-3" dark dense text class="mt-5">
              <v-icon>mdi-tune-vertical</v-icon>
              <v-toolbar-title class="ml-3">{{ $t("admin.donationForm.settings.donationSettings") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col>
                  <div class="text-caption grey--text text--darken-2 my-1">
                    {{ $t("admin.donationForm.settings.gridDescription") }}
                  </div>
                  <v-text-field
                    v-model="donationGrid[index]"
                    :rules="[$v.required(), $v.min(5)]"
                    required
                    v-for="(donation, index) in donationGrid"
                    :key="`donationGrid-${index}`"
                    outlined
                    class="d-inline-block mr-2"
                    style="width: 100px"
                    dense
                    hide-details
                  >
                    <v-icon slot="append" @click="removeDonation(index)" small class="my-1" color="error"
                      >mdi-close
                    </v-icon></v-text-field
                  >
                  <v-btn text tile color="primary" icon @click="addDonation()">
                    <v-icon large>mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-toolbar color="grey darken-3" dark dense text class="mt-5">
              <v-icon>mdi-calendar-today</v-icon>
              <v-toolbar-title class="ml-3">{{
                $t("admin.donationForm.settings.recurringDonationSettings")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-switch
                    :label="$t('admin.donationForm.settings.enableRecurringDonation')"
                    v-model="data.recurringFormEnabled"
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row dense v-show="data.recurringFormEnabled">
                  <v-col>
                    <div class="text-caption grey--text text--darken-2 my-1">
                      {{ $t("admin.donationForm.settings.recurringGridDescription") }}
                    </div>
                    <v-text-field
                      v-model="recurringDonationGrid[index]"
                      :rules="[$v.required(), $v.min(5)]"
                      required
                      v-for="(donation, index) in recurringDonationGrid"
                      :key="`recurringDonationGrid-${index}`"
                      outlined
                      class="d-inline-block mr-2"
                      style="width: 100px"
                      dense
                      hide-details
                      :disabled="!data.recurringFormEnabled"
                    >
                      <v-icon slot="append" @click="removeRecurringDonation(index)" small class="my-1" color="error"
                        >mdi-close
                      </v-icon></v-text-field
                    >
                    <v-btn
                      text
                      tile
                      color="primary"
                      icon
                      @click="addRecurringDonation()"
                      small
                      :disabled="!data.recurringFormEnabled"
                    >
                      <v-icon large>mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-card-text>
            <v-toolbar color="grey darken-3" dark dense text class="mt-5">
              <v-icon>mdi-cog</v-icon>
              <v-toolbar-title class="ml-3">{{ $t("admin.donationForm.settings.otherSettings") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.redirectLink"
                    :label="$t('admin.donationForm.settings.redirectLink')"
                    :rules="[$v.isUrl()]"
                    :hint="$t('admin.donationForm.settings.redirectLinkHint')"
                    persistent-hint
                    :placeholder="$t('admin.donationForm.settings.redirectLinkPlaceholder')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-toolbar color="grey darken-3" dark dense text class="mt-5">
              <v-icon>mdi-calendar-remove</v-icon>
              <v-toolbar-title class="ml-3">{{ $t("admin.donationForm.settings.closeSettings") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedDate"
                        clearable
                        :label="$t('admin.donationForm.settings.closingDate')"
                        append-icon="mdi-calendar"
                        v-on="on"
                        width="150px"
                        class="d-inline-block"
                        :rules="[]"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="data.closingDate" no-title scrollable :min="minDate">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.menu.save(data.closingDate)">{{
                        $t("admin.donationForm.settings.calendarOK")
                      }}</v-btn>
                    </v-date-picker>
                  </v-menu>
                  <a href="javascript:void(0)" class="ml-3" @click="toggleClose">{{ closeButtonText }}</a>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :disabled="!valid" :loading="loading">
                {{ $t("admin.donationForm.settings.save") }}
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";
export default {
  components: {
    quillEditor,
  },
  props: {},
  data() {
    return {
      menu: false,
      valid: false,
      loading: false,
      recurringDonationGridItems: [],
      donationGridItems: [],
      data: {},
      closeButtonText: null,
      minDate: moment().add(1, "day").format("YYYY-MM-DD"),
      rules: {
        futureDate: (value) =>
          moment(value, "DD/MM/YYYY").isAfter(moment(), "day") || this.$t("errors.validator.futureDate"),
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.data = await this.$axios.get(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}`
      );
      this.donationGridItems = this.data ? (this.data.donationGrid ? JSON.parse(this.data.donationGrid) : []) : [];
      this.recurringDonationGridItems = this.data
        ? this.data.recurringDonationGrid
          ? JSON.parse(this.data.recurringDonationGrid)
          : []
        : [];
      this.setCloseButtonText();
    },
    async save() {
      if (!this.$data.valid) return;
      this.data.donationGrid = JSON.stringify(this.donationGridItems);
      this.data.recurringDonationGrid = JSON.stringify(this.recurringDonationGridItems);

      await this.$axios.patch(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}`,
        this.data
      );
      this.setCloseButtonText();
      this.$store.commit("SUCCESS", this.$t("admin.donationForm.settings.msgUpdated"));
    },
    cancel() {},
    clearDate() {
      this.closingDate = null;
    },
    removeDonation(index) {
      this.donationGridItems.splice(index, 1);
    },
    removeRecurringDonation(index) {
      this.recurringDonationGridItems.splice(index, 1);
    },
    addDonation() {
      this.donationGridItems.push("");
    },
    addRecurringDonation() {
      this.recurringDonationGridItems.push("");
    },
    toggleClose() {
      if (!this.isClosed()) {
        this.close();
      } else {
        this.reopen();
      }
    },
    async close() {
      await this.$axios.patch(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/close`
      );
      this.data.closingDate = new Date();
      this.setCloseButtonText();
      this.$store.commit("SUCCESS", this.$t("admin.donationForm.settings.formClosed"));
    },
    async reopen() {
      await this.$axios.patch(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/reopen`
      );
      this.data.closingDate = null;
      this.setCloseButtonText();
      this.$store.commit("SUCCESS", this.$t("admin.donationForm.settings.formreOpened"));
    },
    isClosed() {
      return this.data.closingDate && moment(this.data.closingDate).isSameOrBefore(new Date());
    },
    setCloseButtonText() {
      this.closeButtonText = this.isClosed()
        ? this.$t("admin.donationForm.settings.reOpen")
        : this.$t("admin.donationForm.settings.closeNow");
    },
  },
  computed: {
    formattedDate: {
      get() {
        if (!this.data.closingDate) return null;
        return moment(this.data.closingDate).format("DD/MM/YYYY");
      },
      set(closingDate) {
        const parsedDate = moment(closingDate, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          this.data.closingDate = parsedDate;
        }
        if (closingDate == null) {
          this.data.closingDate = null;
        }
      },
    },
    donationGrid() {
      return this.donationGridItems;
    },
    recurringDonationGrid() {
      return this.recurringDonationGridItems;
    },
  },
};
</script>
