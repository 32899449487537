<template>
  <div>
    <PageCard></PageCard>
    <v-list>
      <v-list-item :to="`/marketing/charity/${$route.params.charityId}/dashboard`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-chart-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.marketing.dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/marketing/charity/${$route.params.charityId}/fundraisers`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.marketing.fundraisers") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/marketing/charity/${$route.params.charityId}/donors`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.marketing.donors") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/marketing/charity/${$route.params.charityId}/custom-export`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-file-export-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.marketing.customExport") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item :to="`/marketing/charity/${$route.params.charityId}/integrations`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-share-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.marketing.integrations") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
  </div>
</template>

<script>
import PageCard from "../../_menu.context.selector.vue";

export default {
  components: {
    PageCard,
  },
};
</script>
