var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("admin.eventFundraising.fundraisers.email.title"))+" ")]),_c('v-form',{staticClass:"mx-6",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail()}},model:{value:(_vm.emailFormValid),callback:function ($$v) {_vm.emailFormValid=$$v},expression:"emailFormValid"}},[(_vm.mutableEmailData)?_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('admin.eventFundraising.fundraisers.email.senderName'),"rules":[_vm.$v.required()]},model:{value:(_vm.mutableEmailData.from_name),callback:function ($$v) {_vm.$set(_vm.mutableEmailData, "from_name", $$v)},expression:"mutableEmailData.from_name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-email-outline","label":_vm.$t('admin.eventFundraising.fundraisers.email.senderEmail'),"rules":[_vm.$v.required(), _vm.$v.isEmail()]},model:{value:(_vm.mutableEmailData.from_email),callback:function ($$v) {_vm.$set(_vm.mutableEmailData, "from_email", $$v)},expression:"mutableEmailData.from_email"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('admin.eventFundraising.fundraisers.email.recipients'),"disabled":true},model:{value:(_vm.mutableEmailData.to[0]['name']),callback:function ($$v) {_vm.$set(_vm.mutableEmailData.to[0], 'name', $$v)},expression:"mutableEmailData.to[0]['name']"}}),_c('v-text-field',{attrs:{"label":_vm.$t('admin.eventFundraising.fundraisers.email.subject'),"rules":[_vm.$v.required()]},model:{value:(_vm.mutableEmailData.subject),callback:function ($$v) {_vm.$set(_vm.mutableEmailData, "subject", $$v)},expression:"mutableEmailData.subject"}}),_c('quill-editor',{attrs:{"options":{
                modules: {
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    [{ align: ['', 'center', 'right'] }],
                    ['link'],
                    ['clean'] ],
                },
                placeholder: _vm.$t('admin.eventFundraising.fundraisers.email.placeholder'),
              }},model:{value:(_vm.mutableEmailData.html),callback:function ($$v) {_vm.$set(_vm.mutableEmailData, "html", $$v)},expression:"mutableEmailData.html"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('admin.eventFundraising.fundraisers.email.sendCopy')},model:{value:(_vm.mutableEmailData.sendCopy),callback:function ($$v) {_vm.$set(_vm.mutableEmailData, "sendCopy", $$v)},expression:"mutableEmailData.sendCopy"}})],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.opened = false}}},[_vm._v(" "+_vm._s(_vm.$t("admin.eventFundraising.fundraisers.email.cancel"))+" ")]),_c('v-btn',{attrs:{"raised":"","color":"primary","type":"submit","disabled":!(_vm.emailFormValid && !!_vm.mutableEmailData.html)}},[_vm._v(" "+_vm._s(_vm.$t("admin.eventFundraising.fundraisers.email.submit"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }