<template>
  <v-card class="pa-5" outlined max-width="300px">
    <v-row>
      <v-col>
        <v-select
          v-model="selectedYear"
          :items="years"
          :label="$t('prop.dateranges.year')"
          :hideDetails="true"
          @change="updateDate"
          outlined
          counter="3"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="selectedMonth"
          :items="months"
          :label="$t('prop.dateranges.month')"
          :hideDetails="true"
          @change="updateDate"
          outlined
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
const moment = require("moment");

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      years: [],
      selectedYear: moment().year(),
      months: [this.$t("prop.dateranges.every"), ...this.$t("prop.daterangepicker.monthNames")],
      selectedMonth: this.$t("prop.dateranges.every"),
    };
  },
  mounted() {
    this.getYearsList();
  },
  methods: {
    updateDate() {
      if (this.selectedMonth == this.$t("prop.dateranges.every")) {
        this.$emit("input", {
          startDate: moment().year(this.selectedYear).startOf("year").format("YYYY-MM-DD"),
          endDate: moment().year(this.selectedYear).endOf("year").format("YYYY-MM-DD"),
        });
      } else {
        this.$emit("input", {
          startDate: moment()
            .year(this.selectedYear)
            .month(this.months.indexOf(this.selectedMonth) - 1)
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: moment()
            .year(this.selectedYear)
            .month(this.months.indexOf(this.selectedMonth) - 1)
            .endOf("month")
            .format("YYYY-MM-DD"),
        });
      }
    },
    async getYearsList() {
      let year = moment().year();

      if (this.$store.state.charity.id) {
        const charity = await this.$axios.get(`/common/charity/${this.$store.state.charity.id}`);
        const creationYear = moment(charity.creationDate).year();

        while (creationYear != year) {
          this.years.push(year);
          year--;
        }
        this.years.push(creationYear);
      } else {
        while (year != 1989) {
          this.years.push(year);
          year--;
        }
      }

      this.updateDate();
    },
  },
};
</script>
