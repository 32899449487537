import Vue from "vue";
import moment from "moment";
import i18n from "./i18n";

import "moment/locale/fr";
moment.locale(i18n.locale);

Vue.filter("date", function (value) {
  if (!value) return "";
  return moment(value).format("DD/MM/YYYY");
});

Vue.filter("time", function (value) {
  if (!value) return "";
  let mDate = moment(value);
  return mDate.subtract(mDate.utcOffset(), "m").format("HH:mm:ss");
});

Vue.filter("datetime", function (value) {
  if (!value) return "";
  let mDate = moment(value);
  return mDate.subtract(mDate.utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
});

Vue.filter("duration", function (value) {
  if (!value) return "";
  return `${parseInt(value / 60) == 0 ? "" : parseInt(value / 60) + "h"}${value % 60 == 0 ? "" : (value % 60) + "m"}`;
});

Vue.filter("amount", function (value, currency) {
  return (
    (Number(value) || 0)
      .toFixed(2)
      .replace(/-/g, "-\u00a0")
      .replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1\u00a0")
      .replace(".", ",") +
    (currency
      ? "\u00a0" +
        ({
          EUR: "€",
          USD: "$",
          GBP: "£",
        }[currency] || currency)
      : "")
  );
});

Vue.filter("weekRange", function (date) {
  let startOfYear = moment(date).startOf("year");
  let startOfWeek = moment(date).startOf("week");
  return (
    (startOfWeek.isBefore(startOfYear) ? startOfYear.format("DD/MM/YYYY") : startOfWeek.format("DD/MM/YYYY")) +
    " - " +
    moment(date).endOf("week").format("DD/MM/YYYY")
  );
});
