<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.eventFundraising.settings.eventProfile.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.eventFundraising.settings.eventProfile.titleDescription") }}
        </v-card-subtitle>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="eventProfile.name"
                  :disabled="!editable"
                  :rules="[$v.required(), $v.maxLength(255)]"
                  :label="$t('admin.eventFundraising.settings.eventProfile.eventName')"
                ></v-text-field>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="!editable"
                      v-model="formattedDate"
                      :label="$t('admin.eventFundraising.settings.eventProfile.eventDate')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="eventProfile.eventDate" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="eventProfile.idCountry"
                  :disabled="!editable"
                  :label="$t('admin.eventFundraising.settings.eventProfile.country')"
                  :items="toolkit.countries"
                  item-text="label"
                  item-value="id"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="eventProfile.externalWebsite"
                  :disabled="!editable"
                  :rules="[$v.isUrl()]"
                  :label="$t('admin.eventFundraising.settings.eventProfile.externalWebsite')"
                  v-show="fundraisingEvent"
                ></v-text-field>
                <ImagePicker
                  ref="logoPicker"
                  v-model="pickerData"
                  v-bind:label="$t('admin.eventFundraising.branding.logo')"
                  logo
                />
                <v-checkbox
                  v-model="eventProfile.companyPodiumEnabled"
                  :label="$t('admin.eventFundraising.settings.eventProfile.companyPodium')"
                  v-show="fundraisingEvent"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn raised color="primary" v-on:click="save()" :disabled="!valid || !editable">{{
            $t("admin.eventFundraising.settings.eventProfile.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ImagePicker from "../../components/image-picker";
const moment = require("moment");

export default {
  components: {
    ImagePicker,
  },
  data() {
    return {
      valid: false,
      editable: true,
      dateMenu: null,
      eventProfile: {},
      fundraisingEvent: false,
      toolkit: {},
      challengePage: {},
      published: false,
      pickerData: {},
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.toolkit = await this.$axios.get("/common/toolkit");
      this.toolkit.countries.map((r) => (r.label = this.$t(r.labelKey)));
      this.toolkit.eventTypes.map((r) => (r.label = this.$t(r.labelKey)));
      let result = await this.$axios.get(
        `/admin/${this.$store.state.charity.id}/eventProfile/${this.$route.params.eventId}`
      );
      this.eventProfile = result.eventProfile;
      this.fundraisingEvent = result.fundraisingEvent;
      this.pickerData = {
        fileName: result.eventProfile.logo,
        url: result.eventProfile.logoUrl,
      };
      this.editable = result.editable;
    },
    async save() {
      if (this.$refs.logoPicker) {
        this.pickerData = await this.$refs.logoPicker.uploadImage();
        if (this.pickerData) this.eventProfile.logo = this.pickerData.fileName;
      }

      if (this.editable) {
        await this.$axios.put(
          `/admin/${this.$store.state.charity.id}/eventProfile/${this.$route.params.eventId}`,
          this.eventProfile
        );
      }
      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.branding.msg_saved"));
    },
  },
  computed: {
    formattedDate: {
      get() {
        if (!this.eventProfile.eventDate) return null;
        return moment(this.eventProfile.eventDate).format("DD/MM/YYYY");
      },
      set(date) {
        const parsedDate = moment(date, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          this.eventProfile.eventDate = parsedDate;
        }
      },
    },
  },
};
</script>

<style></style>
