<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.charity.search.title") }}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    v-model="charityProfile.charityCategories"
                    :items="toolkit.charityCategories"
                    item-text="name"
                    attach
                    :label="$t('admin.charity.search.categories')"
                    multiple
                    return-object
                    v-on:input="limitCategoriesSelection"
                    :hint="`(${charityProfile.charityCategories.length}/5) ${$t(
                      'admin.charity.search.categories_hint'
                    )}`"
                    persistent-hint
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="primary" close @click:close="removeCategoryChip(item)">
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combobox
                    multiple
                    v-model="keywords"
                    :label="$t('admin.charity.search.keywords_note')"
                    append-icon
                    :hint="$t('admin.charity.search.keywords_hint')"
                    persistent-hint
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="primary" close @click:close="removeKeywordChip(item)">
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer />
          <v-btn raised color="primary" v-on:click="save()" :disabled="!valid">{{
            $t("admin.charity.profile.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      toolkit: {},
      charityProfile: {},
      keywords: [],
    };
  },
  mounted() {
    this.getToolkit();
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.charityProfile = await this.$axios.get(`/charity/${this.$store.state.charity.id}/profile`);
      this.keywords = this.charityProfile.keyWords.split(",");
    },
    async save() {
      this.charityProfile.keyWords = this.keywords.join();
      await this.$axios.put(`/charity/${this.$store.state.charity.id}/profile`, this.charityProfile);

      this.$store.commit("SUCCESS", this.$t("admin.charity.profile.msg_saved"));
    },
    async getToolkit() {
      this.toolkit = await this.$axios.get("/common/toolkit");
    },
    limitCategoriesSelection(e) {
      if (e.length > 5) {
        e.pop();
      }
    },
    removeCategoryChip(item) {
      const index = this.charityProfile.charityCategories.map((e) => e.id).indexOf(item.id);
      this.charityProfile.charityCategories.splice(index, 1);
      this.charityProfile.charityCategories = [...this.charityProfile.charityCategories];
    },
    removeKeywordChip(item) {
      const index = this.keywords.indexOf(item);
      this.keywords.splice(index, 1);
      this.keywords = [...this.keywords];
    },
  },
};
</script>

<style></style>
