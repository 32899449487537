<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-form v-model="valid">
        <v-card>
          <v-card-title>
            {{ $t("admin.eventFundraising.branding.title") }}
          </v-card-title>
          <v-card-subtitle class="text-caption">
            {{ $t("admin.eventFundraising.branding.description") }}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col md="6">
                  <ColorPicker
                    :label="$t('admin.eventFundraising.branding.mainColor')"
                    v-model="data.mainColor"
                    v-bind:valid="valid"
                    v-on:update:valid="valid = $event"
                  />
                  <ColorPicker
                    :label="$t('admin.eventFundraising.branding.buttonColor')"
                    v-model="data.buttonColor"
                    v-bind:valid="valid"
                    v-on:update:valid="valid = $event"
                  />
                  <ImageCropper
                    ref="bannerPicker"
                    v-model="data.banner"
                    v-bind:label="$t('admin.eventFundraising.branding.banner')"
                  />
                </v-col>
                <v-col md="6">
                  <v-card class="text-center">
                    <v-card-title class="justify-center">
                      <img :src="data.banner.croppedImage" style="max-width: 100%" v-if="!data.banner.url" />
                      <v-img
                        v-if="data.banner.url"
                        :src="data.banner.url || ''"
                        contain
                        max-width="320px"
                        max-height="240px"
                      >
                      </v-img
                    ></v-card-title>
                    <v-card-text>
                      <h2 class="my-4" v-bind:style="{ color: data.mainColor }">
                        {{ $t("admin.eventFundraising.branding.preview") }}
                      </h2>
                    </v-card-text>
                    <v-card-actions style="justify-content: center">
                      <v-btn class="my-4" :color="data.buttonColor" style="color: #fff">{{
                        $t("admin.eventFundraising.branding.sampleButton")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn raised color="primary" v-on:click="save()" :disabled="!valid">{{
                      $t("admin.eventFundraising.branding.save")
                    }}</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
              <v-row v-if="advancedOptions">
                <v-col lg="8" offset->
                  <v-card tile color="#F6A5A8" class="my-5">
                    <v-card-title style="color: #d91202">{{
                      $t("admin.eventFundraising.branding.advanced")
                    }}</v-card-title>
                    <v-card-subtitle class="text-caption">{{
                      $t("admin.eventFundraising.branding.advanced_subtitle")
                    }}</v-card-subtitle>
                    <v-card-text class="px-2">
                      <ImagePicker
                        ref="logoPicker"
                        v-if="advancedOptions"
                        v-model="data.logo"
                        v-bind:label="$t('admin.eventFundraising.branding.logo')"
                      />
                      <ImagePicker
                        ref="backgroundPicker"
                        v-if="advancedOptions"
                        v-model="data.background"
                        v-bind:label="$t('admin.eventFundraising.branding.backgroundImage')"
                      />
                      <v-container>
                        <v-textarea
                          v-if="advancedOptions"
                          v-model="data.customCss"
                          background-color="rgba(255,255,255,0.5)"
                          auto-grow
                          outlined
                          :label="$t('admin.eventFundraising.branding.customCss')"
                        ></v-textarea>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn raised color="#d91202" v-on:click="save()" :disabled="!valid">{{
                        $t("admin.eventFundraising.branding.save")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<style scoped></style>
<script>
import ColorPicker from "../components/color-picker";
import ImageCropper from "../components/image-cropper";
import ImagePicker from "../components/image-picker";

export default {
  components: {
    ColorPicker,
    ImageCropper,
    ImagePicker,
  },
  data() {
    return {
      data: {
        banner: {},
        logo: {},
        background: {},
      },
      advancedOptions: false,
      valid: false,
    };
  },
  mounted() {
    this.getDataFromApi();
    if (this.$store.state.me.userRoles.indexOf("ROLE_ALVARUM_ADMIN") !== -1) {
      this.advancedOptions = true;
    }
  },
  methods: {
    async getDataFromApi() {
      let result = await this.$axios.get(`/personal/${this.$store.state.charity.id}/branding`);
      this.data = result.data;
    },
    async save() {
      this.data.banner = await this.$refs.bannerPicker.uploadImage();
      if (this.$refs.backgroundPicker) {
        this.data.background = await this.$refs.backgroundPicker.uploadImage();
      }
      if (this.$refs.logoPicker) {
        this.data.logo = await this.$refs.logoPicker.uploadImage();
      }

      await this.$axios.put(`/personal/${this.$store.state.charity.id}/branding`, {
        data: this.data,
      });

      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.branding.msg_saved"));
    },
  },
};
</script>
