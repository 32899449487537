<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.donationForm.links.title") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="7">
              <v-toolbar color="grey darken-3" dark dense flat class="mt-7">
                <v-icon>mdi-link-variant</v-icon>
                <v-toolbar-title class="ml-3">{{ $t("admin.donationForm.links.link") }}</v-toolbar-title>
              </v-toolbar>
              <v-container fluid class="py-6">
                <v-row>
                  <v-col>
                    {{ $t("admin.donationForm.links.linkDesc") }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <a :href="link" class="text-caption" target="_blank">{{ link }}</a>
                    <v-icon color="blue" v-on:click="copyTextToClipboard(link)" class="ml-2">mdi-content-copy</v-icon>
                  </v-col>
                </v-row>
              </v-container>
              <v-toolbar color="grey darken-3" dark dense flat class="mt-7">
                <v-icon>mdi-page-layout-body</v-icon>
                <v-toolbar-title class="ml-3">{{ $t("admin.donationForm.links.embed") }}</v-toolbar-title>
              </v-toolbar>
              <v-container fluid class="py-6">
                <v-row>
                  <v-col>
                    {{ $t("admin.donationForm.links.embedDesc") }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10" lg="8">
                    <v-textarea outlined height="120" :value="embed" @focus="focusEmbedTextBox" readonly />
                  </v-col>
                  <v-col cols="2" lg="4">
                    <v-icon color="blue" v-on:click="copyTextToClipboard(embed)">mdi-content-copy</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col md="4" offset="1">
              <v-card color="#D7FAF0">
                <v-card-title color="secondary">
                  <v-icon class="mr-5">mdi-information-outline</v-icon>
                  {{ $t("admin.donationForm.links.needHelp.title") }}
                </v-card-title>
                <v-card-text color="secondary">
                  <p class="text-justify">
                    {{ $t("admin.donationForm.links.needHelp.text") }}
                  </p>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    :href="$t('admin.donationForm.links.needHelp.link')"
                    target="_blank"
                    raised
                    class="mt-1 mb-5"
                    color="primary"
                    >{{ $t("admin.donationForm.links.needHelp.goToFAQ") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      link: "",
      embed: "",
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let result = await this.$axios.get(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/link`
      );
      this.link = result.link;
      this.embed = result.embed;
    },
    async copyTextToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$store.commit("SUCCESS", this.$t("admin.donationForm.links.msgCopied"));
    },
    async focusEmbedTextBox(event) {
      event.preventDefault();
      event.target.select();
      this.copyTextToClipboard(this.embed);
    },
  },
};
</script>

<style>
.amount {
  margin-left: 1em;
}
</style>
