<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.users.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.users.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog max-width="700px" v-model="createDialog">
            <template v-slot:activator="{ on }">
              <v-btn raised color="primary" v-on="on">
                {{ $t("admin.superadmin.users.actions.create") }}
              </v-btn>
            </template>
            <v-card>
              <v-form @submit.prevent="createUser" ref="createForm" v-model="valid">
                <v-card-title>
                  <span class="headline">{{ $t("admin.superadmin.users.create.title") }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :label="$t('admin.superadmin.users.create.email')"
                        :rules="[$v.required(), $v.isEmail()]"
                        autocomplete="off"
                        name="newUser.login"
                        required
                        v-model="newUser.login"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :label="$t('admin.superadmin.users.create.name')"
                        :rules="[$v.required()]"
                        autocomplete="off"
                        name="newUser.name"
                        required
                        v-model="newUser.name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-for="feature in toolkit.features"
                        :key="feature.id"
                        :label="feature.label"
                        autocomplete="off"
                        name="newUser.name"
                        v-model="newUser.features"
                        :value="feature.id"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="createDialog = false" color="grey" text>
                    {{ $t("prop.cancel") }}
                  </v-btn>
                  <v-btn :disabled="!valid || !newUser.features.length" color="primary" type="submit">
                    {{ $t("prop.create") }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.admin="props">
            <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_APPLICATIONOWNER_ADMIN')"></YesNoIcon>
          </template>
          <template v-slot:item.fundraising="props">
            <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"></YesNoIcon>
          </template>
          <template v-slot:item.payment="props">
            <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_APPLICATIONOWNER_PAYMENT')"></YesNoIcon>
          </template>
          <template v-slot:item.change="props">
            <v-btn color="primary" @click="showEditForm(props.item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog max-width="700px" v-model="updateDialog">
        <v-card>
          <v-form @submit.prevent="updateUser" ref="editForm">
            <input type="hidden" name="id" v-model="editedUser.id" />
            <v-card-title>
              <span class="headline">{{ $t("admin.superadmin.users.update.title") }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col> {{ $t("admin.superadmin.users.create.email") }} {{ editedUser.login }} </v-col>
              </v-row>
              <v-row>
                <v-col> {{ $t("admin.superadmin.users.create.name") }} {{ editedUser.userProfile.userName }} </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-for="feature in toolkit.features"
                    :key="feature.id"
                    :label="feature.label"
                    autocomplete="off"
                    name="newUser.name"
                    v-model="editedUser.features"
                    :value="feature.id"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateDialog = false" color="grey" text>
                {{ $t("prop.cancel") }}
              </v-btn>
              <v-btn color="primary" type="submit">
                {{ $t("prop.update") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import YesNoIcon from "../components/yes-no-icon.vue";
import debounce from "../../../../util/debounce";
export default {
  components: {
    YesNoIcon,
  },
  data() {
    return {
      search: "",
      pagination: {
        itemsPerPage: 10,
      },
      toolkit: {},
      newUser: {
        features: [],
      },
      editedUser: {
        userProfile: {},
      },
      valid: false,
      createDialog: false,
      updateDialog: false,
      totalItems: 0,
      items: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.users.list.user_id"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.users.list.email"),
          value: "login",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.applicationowner.admin"),
          value: "admin",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.applicationowner.fundraising"),
          value: "fundraising",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.applicationowner.payment"),
          value: "payment",
          sortable: false,
        },
        { value: "change", sortable: false },
      ],
    };
  },
  mounted() {
    this.getToolkit();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
    },
  },
  methods: {
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/admins`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
        },
      });
      this.items = result.users.rows;
      this.totalItems = result.users.count;
      this.loading = false;
    },
    simulateUserUrl(user) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/simulateuser?userId=${user.id}`;
    },
    hasRole(user, role) {
      return (
        user.features.filter((val) => {
          return val.label == role;
        }).length > 0
      );
    },
    async getToolkit() {
      this.toolkit = await this.$axios.get("/common/toolkit");
      this.toolkit.features = this.toolkit.features.filter((r) => [2].indexOf(r.backofficeType) !== -1);
      this.toolkit.features.map((r) => (r.label = this.$t(r.propertiesKey)));
    },
    async createUser() {
      const result = await this.$axios.post(`/superadmin/admin`, {
        params: {
          user: this.newUser,
        },
      });
      if (result) {
        this.createUserDialog = false;
        this.$store.commit("SUCCESS", this.$t("admin.charity.users.created"));
        this.createDialog = false;
        this.getDataFromApi();
      } else {
        this.$store.commit("ERROR", this.$t("admin.charity.users.failed"));
      }
    },
    showEditForm(user) {
      this.updateDialog = true;
      let userData = {
        id: user.id,
        login: user.login,
        name: user.name,
        userProfile: user.userProfile,
        features: user.features.map((e) => e.id),
      };
      this.editedUser = userData;
    },
    async updateUser() {
      await this.$axios.put(`/superadmin/admin`, {
        params: {
          user: this.editedUser,
        },
      });
      this.$store.commit("SUCCESS", this.$t("admin.charity.users.updated"));
      this.updateDialog = false;
      this.getDataFromApi();
    },
  },
};
</script>

<style></style>
