<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.charity.profile.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.charity.profile.description") }}
        </v-card-subtitle>
        <v-card-text class="pb-0">
          <v-form v-model="valid">
            <v-container>
              <v-row :style="!!isAppAdmin ? 'background-color: rgb(246, 165, 168)' : ''">
                <v-col>
                  <v-text-field
                    v-model="charityProfile.frontDisplayName"
                    :rules="[$v.required(), $v.maxLength(255)]"
                    :label="$t('admin.charity.profile.name')"
                    :disabled="!isAppAdmin"
                    :hint="!!isAppAdmin ? $t('admin.charity.profile.explanationNote') : ''"
                    :persistent-hint="!!isAppAdmin"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ImagePicker
                    ref="logoPicker"
                    v-model="pickerData"
                    logo
                    :rules="[$v.required()]"
                    v-bind:label="$t('admin.charity.profile.logo')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="charityProfile.description"
                    :rules="[$v.maxLength(500)]"
                    :label="$t('admin.charity.profile.charity_description')"
                    outlined
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <div class="text-caption mx-3 mt-3">
            {{ $t("admin.charity.dashboard.on_since") }} {{ charityProfile.creationDate | date }}
          </div>
          <v-spacer></v-spacer>
          <v-btn raised color="primary" v-on:click="save()" :disabled="!isValid">{{
            $t("admin.charity.profile.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ImagePicker from "../components/image-picker";

export default {
  components: {
    ImagePicker,
  },
  data() {
    return {
      charityProfile: {},
      valid: false,
      pickerData: {},
      isAppAdmin: false,
    };
  },
  mounted() {
    this.getDataFromApi();
    if (this.$store.state.me.userRoles.indexOf("ROLE_ALVARUM_ADMIN") !== -1) {
      this.isAppAdmin = true;
    }
  },
  methods: {
    async getDataFromApi() {
      this.charityProfile = await this.$axios.get(`/charity/${this.$store.state.charity.id}/profile`);
      const charityData = await this.$axios.get(`/common/charity/${this.$store.state.charity.id}`);
      this.pickerData = {
        fileName: this.charityProfile.logo,
        url: charityData.logoUrl,
      };
      this.valid = !!this.pickerData.url;
    },
    async save() {
      if (this.$refs.logoPicker) {
        this.pickerData = await this.$refs.logoPicker.uploadLogo();
        this.pickerData = await this.$refs.logoPicker.uploadLogo();
        if (this.pickerData) this.charityProfile.logo = this.pickerData.fileName;
      }

      await this.$axios.put(`/charity/${this.$store.state.charity.id}/profile`, this.charityProfile);

      this.$store.commit("SUCCESS", this.$t("admin.charity.profile.msg_saved"));
    },
    urlChange(exists) {
      this.valid = exists;
    },
  },
  computed: {
    isValid() {
      return this.valid && !!this.pickerData.url;
    },
  },
};
</script>

<style>
.red-message-color .v-messages__message,
.red-input-color.theme--light.v-input input,
.red-label-color .v-label {
  color: #d91202;
  opacity: 1;
}
</style>
