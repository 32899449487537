<template>
  <v-container>
    <div class="text-caption my-2" v-if="url">
      {{ label }}
    </div>
    <v-img v-if="url" :src="url || ''" contain max-height="240px">
      <v-btn
        @click="
          url = null;
          fileName = null;
        "
        class="float-right ma-1"
        color="error"
        x-small
        v-if="url"
        fab
      >
        <v-icon dark>mdi-delete-forever</v-icon></v-btn
      >
    </v-img>
    <v-file-input
      ref="fileInput"
      v-show="!url"
      :label="label"
      accept="image/png, image/jpeg"
      v-model="file"
      @change="loadImage($event)"
    ></v-file-input>
    <cropper
      ref="cropper"
      v-if="!url"
      class="upload-example-cropper"
      :src="image"
      @change="onChange"
      :stencil-props="{
        minAspectRatio: 3 / 1,
      }"
    />
    <v-card-subtitle class="pa-0 text-caption">{{ $t("admin.eventFundraising.branding.note") }}</v-card-subtitle>
  </v-container>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ImageCropper",
  components: {
    Cropper,
  },
  data() {
    return {
      url: this.value.url,
      image: null,
      file: null,
      valid: false,
      croppedImage: null,
    };
  },
  props: {
    value: Object,
    label: String,
  },
  methods: {
    async uploadImage() {
      // No file was uploaded return existing value
      if (!this.file) {
        if (!this.url) {
          return {
            fileName: null,
          };
        }
        return this.value;
      }
      if (!this.$refs.cropper && this.fileName && this.url) {
        return { fileName: this.fileName, url: this.url };
      }

      // Upload file
      let canvas = this.$refs.cropper.getResult().canvas;
      if (canvas) {
        const form = new FormData();
        let blob = await new Promise((resolve) => canvas.toBlob(resolve, "image/png"));
        form.append("file", blob, this.file.name);
        // Resize image to max width on backend
        form.append("resize", true);

        const response = await this.$axios({
          method: "post",
          url: "/common/fileData",
          data: form,
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.fileName = response.fileName;
        this.url = response.serveURL;
        return { fileName: response.fileName, url: response.serveURL };
      }
    },
    onChange({ coordinates, canvas }) {
      this.result = {
        coordinates,
        canvas,
      };
      this.croppedImage = canvas.toDataURL();
      this.$emit("input", {
        url: this.url,
        croppedImage: this.croppedImage,
        fileName: this.file.name,
        canvas: this.$refs.cropper.getResult().canvas,
      });
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    getImageDimensions(file) {
      return new Promise(function (resolved) {
        var i = new Image();
        i.onload = function () {
          resolved({ w: i.width, h: i.height });
        };
        i.src = file;
      });
    },
    async loadImage(file) {
      if (file) {
        this.image = await this.readFileAsync(file);
        var dimensions = await this.getImageDimensions(this.image);
        this.$refs.cropper.setCoordinates({
          left: 0,
          top: 0,
          width: dimensions.w,
          height: dimensions.h,
        });
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.url = this.value.url;
      },
    },
  },
};
</script>

<style lang="scss">
$base-color: white;
$handler-color: white;
@import "~vue-advanced-cropper/dist/theme.compact.scss";
.vue-simple-handler--west-north {
  box-shadow: -1px -1px 1px black;
}
.vue-simple-handler--east-north {
  box-shadow: 1px -1px 1px black;
}
.vue-simple-handler--east-south {
  box-shadow: 1px 1px 1px black;
}
.vue-simple-handler--west-south {
  box-shadow: -1px 1px 1px black;
}
</style>
