<template>
  <v-row justify="center">
    <v-col sm="8" xl="7">
      <v-card>
        <v-card-title>
          {{ $t("admin.eventFundraising.settings.fundraising.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.eventFundraising.settings.fundraising.description") }}
        </v-card-subtitle>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col md="6" lg="5" xl="3">
                <v-text-field
                  v-model="challengePage.targetAmount"
                  :label="$t('admin.eventFundraising.settings.fundraising.target')"
                  type="number"
                  :min="1"
                  ref="amount"
                  :rules="[$v.min(0)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn raised color="primary" v-on:click="save()" :disabled="!valid || !editable">{{
            $t("admin.eventFundraising.settings.eventProfile.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      editable: true,
      dateMenu: null,
      challengePage: {},
      toolkit: {},
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.challengePage = await this.$axios.get(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/challengePage`
      );
    },
    async save() {
      await this.$axios.put(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/challengePage`,
        this.challengePage
      );
      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.branding.msg_saved"));
    },
  },
};
</script>

<style></style>
