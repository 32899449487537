<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.eventFundraising.settings.notifications.title") }}
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="createNew">{{
            $t("admin.eventFundraising.settings.notifications.createNew")
          }}</v-btn>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.eventFundraising.settings.notifications.description") }}
        </v-card-subtitle>
        <v-container>
          <v-row>
            <v-col lg="10" xl="8">
              <v-list>
                <v-list-item
                  v-for="(notificationsPerEmail, index) in notificationsPerEmails"
                  :key="`notifications-${index}`"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ notificationsPerEmail[0].email }}
                    </v-list-item-title>
                    <div v-for="notification in notificationsPerEmail" :key="notification.id">
                      <v-list-item-subtitle v-if="notification.type == notificationType.PAGE_CREATION">
                        <v-icon small>mdi-bell-outline</v-icon
                        >{{ $t(`admin.eventFundraising.settings.notifications.pageCreation`) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else-if="notification.type == notificationType.PAGE_CLOSED">
                        <v-icon small>mdi-bell-outline</v-icon
                        >{{ $t(`admin.eventFundraising.settings.notifications.pageClosed`) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else-if="notification.type == notificationType.AMOUNT_REACHED">
                        <v-icon small>mdi-bell-outline</v-icon
                        >{{ $t(`admin.eventFundraising.settings.notifications.amountReached`) }}
                        {{ notification.amount | amount("EUR") }}
                      </v-list-item-subtitle>
                    </div>
                    <v-list-item-subtitle>
                      <v-divider light class="mt-3"></v-divider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        raised
                        small
                        v-on="on"
                        color="primary"
                        @click="edit(notificationsPerEmail)"
                      >
                        <v-icon>mdi-clipboard-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("admin.eventFundraising.settings.notifications.actions.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        raised
                        small
                        v-on="on"
                        color="error"
                        @click="removeNotifications(notificationsPerEmail)"
                      >
                        <v-icon>mdi-delete-forever</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("admin.eventFundraising.settings.notifications.actions.delete") }}</span>
                  </v-tooltip>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-dialog v-model="editDialog" max-width="80%" :width="$vuetify.breakpoint.mdAndUp ? '600px' : '900px'">
            <v-card>
              <v-card-title>{{ $t("admin.eventFundraising.settings.notifications.edit") }}</v-card-title>
              <v-container>
                <v-form
                  @submit.prevent="saveNotification"
                  ref="notificationsForm"
                  id="notificationsForm"
                  v-model="notificationFormValid"
                  lazy-validation
                >
                  <v-text-field
                    class="px-3"
                    prepend-icon="mdi-email-outline"
                    v-model="editNotification.email"
                    :label="$t('admin.donationForm.settings.notifications.email')"
                    :rules="[$v.required(), $v.isEmail()]"
                  ></v-text-field>
                  <v-card-title class="px-3">{{
                    $t("admin.eventFundraising.settings.notifications.triggers")
                  }}</v-card-title>
                  <v-card-subtitle class="px-3">{{
                    $t("admin.eventFundraising.settings.notifications.text")
                  }}</v-card-subtitle>
                  <v-list>
                    <v-list-item>
                      <v-checkbox
                        v-model="editNotification.pageCreation"
                        @change="$refs.notificationsForm.resetValidation()"
                        :rules="[
                          $v.isNotificationsFormCorrect([
                            editNotification.pageCreation,
                            editNotification.pageClosed,
                            ...editNotification.amountReacheds,
                          ]),
                        ]"
                      >
                        <span slot="label" :class="editNotification.pageCreation ? 'black--text' : 'grey-2--text'">
                          {{ $t("admin.eventFundraising.settings.notifications.pageCreation") }}
                        </span>
                      </v-checkbox>
                    </v-list-item>
                    <v-list-item>
                      <v-checkbox
                        v-model="editNotification.pageClosed"
                        @change="$refs.notificationsForm.resetValidation()"
                        :rules="[
                          $v.isNotificationsFormCorrect([
                            editNotification.pageCreation,
                            editNotification.pageClosed,
                            ...editNotification.amountReacheds,
                          ]),
                        ]"
                      >
                        <span slot="label" :class="editNotification.pageClosed ? 'black--text' : 'grey-2--text'">
                          {{ $t("admin.eventFundraising.settings.notifications.pageClosed") }}
                        </span>
                      </v-checkbox>
                    </v-list-item>
                    <v-list-item :key="index" v-for="(amountReached, index) in editNotification.amountReacheds">
                      <v-checkbox
                        v-model="editNotification.amountReacheds[index]"
                        @change="amountReachedCheckboxChange($event, index)"
                        :rules="[
                          $v.isNotificationsFormCorrect([
                            editNotification.pageCreation,
                            editNotification.pageClosed,
                            ...editNotification.amountReacheds,
                          ]),
                        ]"
                      >
                        <span
                          slot="label"
                          :class="editNotification.amountReacheds[index] ? 'black--text' : 'grey-2--text'"
                        >
                          {{ `${$t("admin.eventFundraising.settings.notifications.amountReached")}:` }}
                        </span>
                      </v-checkbox>
                      <v-text-field
                        class="px-5"
                        append-icon="€"
                        v-model="editNotification.amounts[index]"
                        :label="$t('admin.eventFundraising.settings.notifications.amount')"
                        type="number"
                        min="0"
                        :rules="editNotification.amountReacheds[index] ? [$v.min(1), $v.required()] : []"
                        :ref="`amountTextField${index}`"
                        @input="amountChange($event, index)"
                      >
                      </v-text-field>
                      <v-btn
                        v-if="index == editNotification.amountReacheds.length - 1"
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="addNewAmountField()"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-list-item>
                  </v-list>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="editDialog = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn raised type="submit" color="primary" form="notificationsForm">
                  {{ $t("submit") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <v-card v-if="isNotificationsEmpty" class="d-flex align-center justify-center pa-4 mx-auto" outlined>
          <div class="font-weight-thin">
            {{ $t("admin.eventFundraising.settings.notifications.noNotificationsText") }}
          </div>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const notificationType = Object.freeze({
  PAGE_CREATION: "PAGE_CREATION",
  PAGE_CLOSED: "PAGE_CLOSED",
  AMOUNT_REACHED: "AMOUNT_REACHED",
});

export default {
  data() {
    return {
      editDialog: false,
      editMode: false,
      notificationFormValid: false,

      editNotification: {
        email: "",
        pageCreation: false,
        pageClosed: false,
        amountReacheds: [false],
        amounts: [null],
      },
      notificationsPerEmails: {},
      notificationType,
    };
  },
  computed: {
    isNotificationsEmpty: function () {
      return this.notificationsPerEmails && Object.keys(this.notificationsPerEmails).length === 0;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let result = await this.$axios.get(`/personal/${this.$store.state.charity.id}/notifications`);
      const emails = [...new Set(result.map((item) => item.email))];
      const notificationsPerEmails = {};
      for (const email of emails) {
        notificationsPerEmails[email] = result.filter((item) => item.email == email);
      }
      this.notificationsPerEmails = notificationsPerEmails;
    },
    createNew() {
      if (this.$refs.notificationsForm != undefined) this.$refs.notificationsForm.resetValidation();
      this.editNotification = {
        email: "",
        pageCreation: false,
        pageClosed: false,
        amountReacheds: [false],
        amounts: [null],
      };
      this.editMode = false;
      this.editDialog = true;
    },
    edit(item) {
      this.editMode = true;
      this.editNotification = {
        email: item[0].email,
        pageCreation: false,
        pageClosed: false,
        amountReacheds: [false],
        amounts: [null],
      };
      for (const notification of item) {
        if (notification.type == notificationType.PAGE_CREATION) {
          this.editNotification.pageCreation = true;
        }
        if (notification.type == notificationType.PAGE_CLOSED) {
          this.editNotification.pageClosed = true;
        }
        if (notification.type == notificationType.AMOUNT_REACHED) {
          const lastIndex = this.editNotification.amountReacheds.length - 1;
          if (this.editNotification.amountReacheds[lastIndex] == false) {
            this.editNotification.amountReacheds[lastIndex] = true;
            this.editNotification.amounts[lastIndex] = notification.amount;
          } else {
            this.editNotification.amountReacheds.push(true);
            this.editNotification.amounts.push(notification.amount);
          }
        }
      }
      this.editDialog = true;
    },
    async saveNotification() {
      if (!this.$refs.notificationsForm.validate()) return;

      const data = this.prepareDataToSend();
      if (this.editMode || this.isEmailExists(this.editNotification.email))
        this.removeNotifications(this.notificationsPerEmails[this.editNotification.email]);
      for (const d of data) {
        await this.$axios.post(`/personal/${this.$store.state.charity.id}/notifications`, d);
      }

      if (this.editMode)
        this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.settings.notifications.msg_updated"));
      else this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.settings.notifications.msg_created"));

      this.getDataFromApi();
      this.editDialog = false;
    },
    async removeNotifications(notificationsPerEmail) {
      for (const notification of notificationsPerEmail) {
        await this.$axios.delete(`/personal/${this.$store.state.charity.id}/notifications/${notification.id}`);
      }
      if (!this.editMode)
        this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.settings.notifications.msg_removed"));
      this.getDataFromApi();
    },
    prepareDataToSend() {
      let data = [];
      if (this.editNotification.pageCreation) {
        data.push({
          email: this.editNotification.email,
          type: notificationType.PAGE_CREATION,
          amount: null,
        });
      }
      if (this.editNotification.pageClosed) {
        data.push({
          email: this.editNotification.email,
          type: notificationType.PAGE_CLOSED,
          amount: null,
        });
      }
      for (const [i, amountReached] of this.editNotification.amountReacheds.entries()) {
        if (amountReached) {
          data.push({
            email: this.editNotification.email,
            type: notificationType.AMOUNT_REACHED,
            amount: this.editNotification.amounts[i],
          });
        }
      }
      return data;
    },
    isAmountValid(amount) {
      let result = false;
      if (amount != "" && amount != null && (amount >= 0 || !amount)) result = true;
      return result;
    },
    amountReachedCheckboxChange(value, index) {
      this.$refs.notificationsForm.resetValidation();
      if (value == true) this.$refs[`amountTextField${index}`][0].focus();
    },
    amountChange(value, index) {
      if (this.isAmountValid(value)) this.editNotification.amountReacheds[index] = true;
      else this.editNotification.amountReacheds[index] = false;
    },
    addNewAmountField() {
      this.editNotification.amountReacheds.push(false);
      this.editNotification.amounts.push(null);
    },
    isEmailExists(email) {
      return email in this.notificationsPerEmails;
    },
  },
};
</script>

<style>
.amount {
  margin-left: 1em;
}
</style>
