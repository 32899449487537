<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-dialog max-width="700px" v-model="createDialog">
        <v-card>
          <v-form @submit.prevent="createEvent" ref="createForm" v-model="valid">
            <v-card-title>
              <span class="headline">{{ $t("admin.personalFundraising.settings.challenges.create.title") }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('admin.personalFundraising.settings.challenges.create.campaignName')"
                    :rules="[$v.required()]"
                    autocomplete="off"
                    name="newEvent.name"
                    required
                    v-model="newEvent.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="newEvent.campaignType"
                    :items="toolkit.campaignTypes"
                    item-text="label"
                    item-value="id"
                    :label="$t('admin.personalFundraising.settings.challenges.create.campaignType')"
                  >
                    <!--                    <template v-slot:item="data">
                      <div class="py-2">
                        <div>{{ data.item.label }}</div>
                        <div class="text-caption">{{ data.item.description }}</div>
                      </div>
                    </template> -->
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menuDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDate"
                        :label="newEventDateLabel"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="[rules.futureDate]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newEvent.date"
                      @input="menuDatePicker = false"
                      :min="minDate"
                      :first-day-of-week="1"
                      :locale="$i18n.locale == 'en' ? 'en' : 'fr'"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="newEvent.idCountry"
                    :label="$t('admin.personalFundraising.settings.challenges.create.country')"
                    :items="toolkit.countries"
                    item-text="label"
                    item-value="id"
                    :rules="[$v.required()]"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions
              style="background: #d7faf0"
              class="pa-4"
              v-if="newEvent.campaignType == 1 && createDialogShowPanel"
            >
              <div class="text-justify mr-5">
                <v-icon class="mr-1 mb-1">mdi-information-outline</v-icon>
                {{ $t("admin.personalFundraising.settings.challenges.create.infoDisplay.text") }}
              </div>
              <v-spacer></v-spacer>
              <v-container class="px-0">
                <v-row dense align-content="center">
                  <v-col cols="12" class="text-center">
                    <v-btn text v-on:click="createDialogShowPanel = false">
                      {{ $t("admin.personalFundraising.settings.challenges.create.infoDisplay.noThanks") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn :href="callMeBackUrl" target="_blank" color="primary">
                      {{ $t("admin.personalFundraising.settings.challenges.create.infoDisplay.callMeBack") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
            <v-card-actions v-if="!(newEvent.campaignType == 1 && createDialogShowPanel)">
              <v-spacer></v-spacer>
              <v-btn @click="createDialog = false" color="grey" text>
                {{ $t("admin.personalFundraising.settings.challenges.create.cancel") }}
              </v-btn>
              <v-btn :disabled="!valid" color="primary" type="submit">
                {{ $t("admin.personalFundraising.settings.challenges.create.submit") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-card class="page-card">
        <v-card-title>
          <h2>
            {{ $t("admin.eventFundraising.events.title") }}
          </h2>
          <v-btn class="ml-10" color="primary" v-on:click="createDialog = true">
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{ $t("admin.eventFundraising.events.actions.add") }}
          </v-btn>
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" md="6" lg="7">
              <v-list>
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :to="`/admin/charity/${$route.params.charityId}/events/${item.eventId}/dashboard`"
                  ripple
                  color="primary"
                  :two-line="!item.fundraisingEvent"
                  :three-line="!!item.fundraisingEvent"
                  class="event-list-item py-2"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>{{ item.name }}</h3></v-list-item-title
                    >
                    <v-list-item-subtitle v-if="item.fundraisingEvent">
                      <v-chip small color="secondary" text-color="white">{{
                        $t("admin.eventFundraising.events.fundraisingEvent")
                      }}</v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.eventDate | date }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary">
                      {{ $t("admin.eventFundraising.events.actions.manage") }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div v-if="isEventsListEmpty" class="pa-4">
                <p class="font-weight-thin text-center">
                  {{ $t("admin.eventFundraising.events.noEventsText") }}
                </p>
                <p class="text-center">
                  {{ $t("admin.eventFundraising.events.organizingEventText") }}<br />
                  <a v-on:click="createDialog = true">
                    {{ $t("admin.eventFundraising.events.organizingEventLink") }}
                  </a>
                </p>
              </div>
              <div class="text-center mt-5" v-if="hasMore">
                <v-btn text @click="showPastEvents()" color="primary">
                  {{ $t("admin.eventFundraising.events.showPastEvents") }}
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="6" lg="5">
              <v-card color="#D7FAF0">
                <v-card-title color="secondary">
                  <v-icon class="mr-5">mdi-information-outline</v-icon>
                  {{ $t("admin.eventFundraising.events.organizing.title") }}
                </v-card-title>
                <v-card-text color="secondary">
                  <p class="text-justify">
                    {{ $t("admin.eventFundraising.events.organizing.text") }}
                  </p>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn :href="callMeBackUrl" target="_blank" raised class="mt-1 mb-5" color="primary">{{
                    $t("admin.eventFundraising.events.organizing.callMeBack")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      totalItems: 0,
      hasMore: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      loading: true,
      createDialog: false,
      createDialogShowPanel: true,
      valid: false,
      newEvent: {
        idCountry: 74,
        campaignType: null,
        date: moment().add(2, "months").format("YYYY-MM-DD"),
      },
      menuDatePicker: false,
      minDate: moment().add(1, "day").format("YYYY-MM-DD"),
      toolkit: {},
      callMeBackUrl: "",
      rules: {
        futureDate: (value) =>
          moment(value, "DD/MM/YYYY").isAfter(moment(), "day") || this.$t("errors.validator.futureDate"),
      },
    };
  },
  computed: {
    isEventsListEmpty() {
      return !this.items?.length ? true : false;
    },
    newEventDateLabel() {
      if (this.newEvent.campaignType == 1 || this.newEvent.campaignType == 2) {
        return this.$t("admin.personalFundraising.settings.challenges.create.eventdate");
      }
      return this.$t("admin.personalFundraising.settings.challenges.create.date");
    },
    formattedDate: {
      get() {
        if (!this.newEvent.date) return null;
        return moment(this.newEvent.date).format("DD/MM/YYYY");
      },
      set(date) {
        const parsedDate = moment(date, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          this.newEvent.date = parsedDate;
        }
      },
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getToolkit();
  },
  methods: {
    async createEvent() {
      const result = await this.$axios.post(`/personal/${this.$store.state.charity.id}/challengePages/create`, {
        event: this.newEvent,
      });
      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.events.msg_created"));
      this.$router.push(`/admin/charity/${this.$store.state.charity.billingId}/events/${result.eventId}/dashboard`);
    },
    async showPastEvents() {
      const { page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/admin/${this.$store.state.charity.id}/events/past`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
        },
      });
      this.hasMore = result.hasMore;
      result.rows.forEach((row) => {
        this.items.push(row);
      });
      this.loading = false;
      this.pagination.page++;
    },
    async getDataFromApi() {
      this.loading = true;
      const result = await this.$axios.get(`/admin/${this.$store.state.charity.id}/events`);
      result.rows.forEach((row) => {
        this.items.push(row);
      });

      const link = await this.$axios.get(`/admin/${this.$store.state.charity.id}/link`);
      this.callMeBackUrl = link.eventCreationUrl;

      this.loading = false;
    },
    async getToolkit() {
      this.toolkit = await this.$axios.get("/common/toolkit");
      this.toolkit.countries.map((r) => (r.label = this.$t(r.labelKey)));
      this.toolkit.eventTypes.map((r) => (r.label = this.$t(r.labelKey)));
      this.toolkit.campaignTypes.map((r) => (r.label = this.$t(r.labelKey + ".title")));
      //      this.toolkit.campaignTypes.map((r) => (r.description = this.$t(r.labelKey + ".description")));
    },
  },
};
</script>

<style>
.v-data-footer__select {
  display: none;
}
.event-list-item {
  border-bottom: 1px solid #ddd;
}
.event-list-item:last-child {
  border-bottom: none;
}
.page-card {
  min-height: 500px;
}
</style>
