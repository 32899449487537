<template>
  <v-dialog v-model="detailsDialog" max-width="700">
    <v-card>
      <v-app-bar>
        <v-toolbar-title>
          <v-avatar size="32" class="mr-3">
            <img alt="user" :src="detailsProp.imageUrl" />
          </v-avatar>
          {{ detailsProp.firstName }} {{ detailsProp.lastName }}
          <v-btn icon :href="detailsProp.actionHomeUrl" target="_blank">
            <v-icon color="blue"> mdi-open-in-new</v-icon>
          </v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-on:click="detailsDialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-4">
              <v-img style="max-height: 20vh" :src="detailsProp.imageUrl" contain></v-img>
            </v-col>
            <v-col class="col-6">
              <v-list>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="detailsProp.groupUrl">
                    <span class="font-weight-bold">{{ $t("admin.eventFundraising.fundraisers.details.group") }}: </span>
                    <a :href="detailsProp.groupUrl" target="_blank">{{ detailsProp.groupName }} </a>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span class="font-weight-bold"
                      >{{ $t("admin.eventFundraising.fundraisers.details.created") }}:
                    </span>
                    <span>{{ detailsProp.creationDate | date }}</span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="detailsProp.targetAmount > 0">
                    <span class="font-weight-bold"
                      >{{ $t("admin.eventFundraising.fundraisers.details.target") }}:
                    </span>
                    <span>{{ detailsProp.targetAmount | amount("EUR") }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span class="font-weight-bold"
                      >{{ $t("admin.eventFundraising.fundraisers.details.onlineDonations") }}:
                    </span>
                    <span> {{ detailsProp.risedOnline | amount("EUR") }}</span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="detailsProp.risedOffline">
                    <span class="font-weight-bold"
                      >{{ $t("admin.eventFundraising.fundraisers.details.offlineDonations") }}:
                    </span>
                    <span>{{ detailsProp.risedOffline | amount("EUR") }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-tabs fixed-tabs>
          <v-tab>
            {{ $t("admin.eventFundraising.fundraisers.details.tabs.onlineDonations") }}
          </v-tab>
          <v-tab>
            {{ $t("admin.eventFundraising.fundraisers.details.tabs.offlineDonations") }}
          </v-tab>
          <v-tab-item>
            <v-data-table
              dense
              :headers="donationsTable.headers"
              :items="donationsTable.items"
              :loading="donationsTable.loading"
              :options.sync="donationsPagination"
              :server-items-length="donationsTable.totalItems"
              class="my-3 no-head"
              item-key="id"
            >
              <template v-slot:item.donorFirstName="props">
                <div class="one-line nowrap text-caption font-weight-bold">
                  {{ props.item.donorFirstName }} {{ props.item.donorLastName }}
                </div>
                <div class="text-caption">
                  {{ props.item.creationDate | date }}
                </div>
              </template>
              <template v-slot:item.creationDate="props">
                {{ props.item.creationDate | date }}
              </template>
              <template v-slot:item.comment="props">
                <div class="one-line text-caption" :title="props.item.comment">
                  {{ props.item.comment }}
                </div>
              </template>
              <template v-slot:item.amount="props">
                <div class="font-weight-bold">
                  {{ props.item.amount | amount("EUR") }}
                </div>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              v-if="!addDonationVisible"
              :headers="offlineDonationsTable.headers"
              :items="offlineDonationsTable.items"
              :loading="offlineDonationsTable.loading"
              :options.sync="offlineDonationsPagination"
              :server-items-length="offlineDonationsTable.totalItems"
              class="my-3 no-head"
              item-key="id"
            >
              <template v-slot:item.donorFirstName="props">
                <div class="one-line nowrap text-caption font-weight-bold">
                  {{ props.item.donorFirstName }} {{ props.item.donorLastName }}
                </div>
                <div class="text-caption">
                  {{ props.item.dataEntryDate | date }}
                </div>
              </template>
              <template v-slot:item.stayAnonymous="props">
                <v-icon v-if="props.item.stayAnonymous">mdi-eye-off</v-icon>
                <v-icon v-if="!props.item.stayAnonymous" color="green">mdi-eye</v-icon>
              </template>
              <template v-slot:item.dataEntryDate="props">
                {{ props.item.dataEntryDate | date }}
              </template>
              <template v-slot:item.comment="props">
                <div class="one-line text-caption" :title="props.item.comment">
                  {{ props.item.comment }}
                </div>
              </template>
              <template v-slot:item.amountCents="props">
                <div class="font-weight-bold">
                  {{ (props.item.amountCents / 100) | amount("EUR") }}
                </div>
              </template>
              <template v-slot:item.delete="props">
                <v-btn v-if="!disableRegisterDonation" icon v-on:click="editDonation(props.item)"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
                <v-btn v-if="!disableRegisterDonation" icon v-on:click="deleteDonation(props.item)"
                  ><v-icon color="error">mdi-delete-forever-outline</v-icon></v-btn
                >
              </template>
            </v-data-table>
            <v-container v-if="addDonationVisible">
              <v-form v-model="formValid">
                <v-row no-gutters>
                  <v-col cols="12" sm="4" class="pr-sm-3">
                    <v-text-field
                      v-model="offlineDonation.firstName"
                      :label="$t('admin.eventFundraising.fundraisers.details.registerOffline.firstName')"
                      type="text"
                      ref="firstName"
                      :rules="[$v.required(), $v.maxLength(50)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="offlineDonation.lastName"
                      :label="$t('admin.eventFundraising.fundraisers.details.registerOffline.lastName')"
                      type="text"
                      ref="lastName"
                      :rules="[$v.required(), $v.maxLength(100)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="offlineDonation.comment"
                      :label="$t('admin.eventFundraising.fundraisers.details.registerOffline.comment')"
                      type="text"
                      ref="comment"
                      :rules="[$v.maxLength(255)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="offlineDonation.amount"
                      :label="$t('admin.eventFundraising.fundraisers.details.registerOffline.amount')"
                      type="number"
                      :min="1"
                      ref="amount"
                      :rules="[$v.required(), $v.min(1)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      v-model="offlineDonation.stayAnonymous"
                      :true-value="false"
                      :false-value="true"
                      :label="$t('admin.eventFundraising.fundraisers.details.registerOffline.discloseName')"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn v-on:click="addDonationVisible = false" outlined class="mt-5 ml-5">{{
                    $t("admin.eventFundraising.fundraisers.create.cancel")
                  }}</v-btn>
                  <v-btn
                    v-if="!editMode"
                    :disabled="!formValid"
                    outlined
                    primary
                    class="mt-5 ml-5"
                    @click="saveOfflineDonation(offlineDonation)"
                  >
                    {{ $t("admin.eventFundraising.fundraisers.details.btnSaveOfflineDonation") }}
                  </v-btn>
                  <v-btn
                    v-if="editMode"
                    :disabled="!formValid"
                    outlined
                    primary
                    class="mt-5 ml-5"
                    @click="updateOfflineDonation(offlineDonation)"
                  >
                    {{ $t("prop.update") }}
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
            <v-btn class="my-2" v-if="!addDonationVisible && !disableRegisterDonation" v-on:click="createDonation()">{{
              $t("admin.eventFundraising.fundraisers.details.registerOffline.title")
            }}</v-btn>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FundraiserDetails",
  components: {},
  data() {
    return {
      detailsProp: this.value,
      detailsDialog: false,
      addDonationVisible: false,
      idFundraisingPage: false,
      donationsTable: {
        headers: [
          { value: "donorFirstName", sortable: false },
          { value: "comment", sortable: false },
          { value: "amount", sortable: false },
          // { value: "creationDate", sortable: false }
        ],
        items: [],
        totalItems: 0,
      },
      offlineDonationsTable: {
        headers: [
          { value: "stayAnonymous", sortable: false },
          { value: "donorFirstName", sortable: false },
          { value: "comment", sortable: false },
          { value: "amountCents", sortable: false },
          // { value: "dataEntryDate", sortable: false },
          { value: "delete", sortable: false },
        ],
        items: [],
        totalItems: 0,
      },
      donationsPagination: {
        page: 1,
        itemsPerPage: 5,
      },
      offlineDonationsPagination: {
        page: 1,
        itemsPerPage: 5,
      },
      offlineDonation: {},
      editMode: {},
      formValid: false,
    };
  },
  props: {
    value: Object,
    urlPrefix: String,
    disableRegisterDonation: Boolean,
  },
  watch: {
    donationsPagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    offlineDonationsPagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    value: {
      handler() {
        this.detailsProp = this.value;
      },
    },
  },
  methods: {
    async show(idFundraisingPage) {
      this.donationsPagination = {
        page: 1,
        itemsPerPage: 5,
      };
      this.offlineDonationsPagination = {
        page: 1,
        itemsPerPage: 5,
      };
      this.idFundraisingPage = idFundraisingPage;
      this.getDataFromApi(idFundraisingPage);
      this.detailsDialog = true;
    },
    async createDonation() {
      this.offlineDonation = {};
      this.addDonationVisible = true;
      this.editMode = false;
    },
    async editDonation(item) {
      item.firstName = item.donorFirstName;
      item.lastName = item.donorLastName;
      item.amount = item.amountCents / 100;
      item.oldAmount = item.amountCents / 100;
      this.offlineDonation = item;
      this.addDonationVisible = true;
      this.editMode = true;
    },
    async saveOfflineDonation(item) {
      item.idFundraisingPage = this.detailsProp.idFundraisingPage;
      await this.$axios.post(
        `/${this.urlPrefix}/${this.$store.state.charity.id}/fundraisers/${this.detailsProp.idFundraisingPage}/offlineDonation`,
        { offlineDonation: item }
      );
      this.detailsProp.risedOffline += parseFloat(item.amount);
      this.detailsProp.numDonationsOffline++;
      await this.getDataFromApi();
      this.addDonationVisible = false;
    },
    async updateOfflineDonation(item) {
      await this.$axios.patch(
        `/${this.urlPrefix}/${this.$store.state.charity.id}/fundraisers/${this.detailsProp.idFundraisingPage}/offlineDonation`,
        { offlineDonation: item }
      );
      this.detailsProp.risedOffline += parseFloat(item.amount) - parseFloat(item.oldAmount);
      await this.getDataFromApi();
      this.addDonationVisible = false;
    },
    async deleteDonation(item) {
      if (confirm(this.$t("admin.eventFundraising.fundraisers.details.confirmDelete"))) {
        await this.$axios.delete(
          `/${this.urlPrefix}/${this.$store.state.charity.id}/fundraisers/${this.idFundraisingPage}/offlineDonation/${item.id}`
        );
        this.detailsProp.risedOffline -= parseFloat(item.amountCents / 100);
        await this.getDataFromApi();
      }
    },
    async getDonationsData() {
      const result = await this.$axios.get(
        `/${this.urlPrefix}/${this.$store.state.charity.id}/donations/${this.idFundraisingPage}`,
        {
          params: {
            page: this.donationsPagination.page - 1,
            size: this.donationsPagination.itemsPerPage,
          },
        }
      );
      this.donationsTable.items = result.result.rows;
      this.donationsTable.totalItems = result.result.count;
    },
    async getOfflineDonationsData() {
      const result2 = await this.$axios.get(
        `/${this.urlPrefix}/${this.$store.state.charity.id}/offlineDonations/${this.idFundraisingPage}`,
        {
          params: {
            page: this.offlineDonationsPagination.page - 1,
            size: this.offlineDonationsPagination.itemsPerPage,
          },
        }
      );
      this.offlineDonationsTable.items = result2.result.rows;
      this.offlineDonationsTable.totalItems = result2.result.count;
    },
    async getDataFromApi() {
      this.getDonationsData();
      this.getOfflineDonationsData();
    },
  },
};
</script>

<style>
.no-head thead {
  display: none;
}

.v-data-table .one-line {
  max-height: 1.5em;
  overflow: hidden;
}

.v-data-table .nowrap {
  white-space: nowrap;
}
</style>
