<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.personalFundraising.inMemoriamPage.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.personalFundraising.inMemoriamPage.titleDescription") }}
        </v-card-subtitle>
        <v-alert class="mx-4" type="info" color="#1fcc9c" text dense>
          {{ $t("admin.eventFundraising.coachingEmails.inMemoriamNote") }}
        </v-alert>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("admin.personalFundraising.inMemoriamPage.links.cp_title") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("admin.personalFundraising.inMemoriamPage.links.cp_subtitle") }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-btn text color="blue" :href="url" target="_blank">
                  {{ $t("admin.personalFundraising.inMemoriamPage.links.cp_subtitle2") }}
                </v-btn>
                <v-icon color="blue" small v-on:click="copyTextToClipboard(url)"> mdi-content-copy </v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let result = await this.$axios.get(`/personal/${this.$store.state.charity.id}/links`);
      this.url = result.inMemoriamCreationPageUrl;
    },
    async copyTextToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.links.msg_copied"));
    },
  },
};
</script>

<style></style>
