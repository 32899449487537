<template>
  <div>
    <PageCard></PageCard>
    <v-list>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/dashboard`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-chart-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/fundraisers`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.fundraisers") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/groups`"
        ripple
        color="primary"
        v-if="groupsVisible"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.groups") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-5" />
      <!--      <v-subheader>{{ $t("admin.menu.settings.title") }}</v-subheader> -->
      <v-list-item
        v-if="isRegistrationEvent && !isOwner"
        :href="linkToEventParticipationBackoffice"
        ripple
        color="primary"
        style="background: #fecedc"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-icon>
          <v-icon>mdi-timer-marker-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("admin.menu.settings.referents") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="isOwner"
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/settings/eventProfile`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.eventProfile") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/settings/campaignPage`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-text-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.campaignPage") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/branding`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-palette</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.design") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/settings/coaching`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.coaching") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/admin/charity/${$route.params.charityId}/events/${$route.params.eventId}/settings/notifications`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-bell-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.notifications") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PageCard from "../../_menu.context.selector.vue";

export default {
  data() {
    return {
      groupsVisible: false,
      isRegistrationEvent: false,
      isOwner: false,
    };
  },
  computed: {
    linkToEventParticipationBackoffice() {
      return `${this.$store.state.settings.eventManagementBaseUrl}/team-space/fundraising/${this.$store.state.charity.billingId}/${this.$route.params.eventId}`;
    },
  },
  async mounted() {
    const event = await this.$axios.get(`/common/event/${this.$route.params.eventId}`);
    this.isOwner = event.idOwningCharity == this.$store.state.charity.id;
    this.isRegistrationEvent = event.isRegistrationEvent;

    const result2 = await this.$axios.get(
      `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/challengePage`
    );
    this.challengePage = result2.challengePage;
    this.groupsVisible = this.challengePage.enableGroupPages;
  },
  components: {
    PageCard,
  },
};
</script>
