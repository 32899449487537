var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xl":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("admin.eventFundraising.settings.campaignPage.title"))+" "),(_vm.url)?_c('v-btn',{attrs:{"icon":"","href":_vm.url,"target":"_blank","color":"primary","title":_vm.$t('admin.menu.go_to_landing')}},[_c('v-icon',[_vm._v("mdi-launch")])],1):_vm._e()],1),_c('v-card-subtitle',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("admin.eventFundraising.settings.campaignPage.titleDescription"))+" ")]),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('quill-editor',{attrs:{"options":{
                  modules: {
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                      [{ align: ['', 'center', 'right'] }],
                      ['link'],
                      ['clean'] ],
                  },
                  placeholder: _vm.$t('admin.eventFundraising.settings.campaignPage.description'),
                }},model:{value:(_vm.challengePage.description),callback:function ($$v) {_vm.$set(_vm.challengePage, "description", $$v)},expression:"challengePage.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{ref:"amount",attrs:{"label":_vm.$t('admin.eventFundraising.settings.fundraising.target'),"type":"number","min":1,"rules":[_vm.$v.min(0)]},model:{value:(_vm.challengePage.targetAmount),callback:function ($$v) {_vm.$set(_vm.challengePage, "targetAmount", $$v)},expression:"challengePage.targetAmount"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('admin.eventFundraising.settings.campaignPage.published')},model:{value:(_vm.published),callback:function ($$v) {_vm.published=$$v},expression:"published"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('admin.eventFundraising.settings.campaignPage.enableGroupPages')},model:{value:(_vm.challengePage.enableGroupPages),callback:function ($$v) {_vm.$set(_vm.challengePage, "enableGroupPages", $$v)},expression:"challengePage.enableGroupPages"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"raised":"","color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("admin.eventFundraising.settings.eventProfile.save")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }