<template>
  <v-container>
    <div class="text-caption my-2" v-if="url">
      {{ label }}
    </div>
    <v-img v-if="url" :src="url" contain max-width="320px" max-height="240px">
      <v-btn @click="clearImage()" class="float-right ma-1" color="error" x-small v-if="url" fab>
        <v-icon dark>mdi-delete-forever</v-icon></v-btn
      >
    </v-img>
    <v-file-input
      ref="fileInput"
      v-show="!url"
      :label="label"
      accept="image/png, image/jpeg"
      v-model="file"
      @change="loadImage($event)"
      :rules="isValid"
    ></v-file-input>
  </v-container>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ImagePicker",
  data() {
    return {
      url: this.value.url,
      image: null,
      file: null,
      valid: false,
    };
  },
  props: {
    value: Object,
    required: {
      type: Boolean,
      defaul: false,
    },
    label: String,
    logo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async uploadImage() {
      // No file was uploaded return existing value
      if (!this.file) {
        if (!this.url) {
          return {
            fileName: null,
          };
        }
        return this.value;
      }
      // Upload file
      const form = new FormData();
      form.append("file", this.file);
      if (this.logo) {
        form.append("resize", true);
        form.append("isLogo", this.logo);
      }
      const response = await this.$axios({
        method: "post",
        url: "/common/fileData",
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return { fileName: response.fileName, url: response.serveURL };
    },
    async uploadLogo() {
      // No file was uploaded return existing value
      if (!this.file) {
        if (!this.url) {
          return {
            fileName: null,
          };
        }
        return this.value;
      }
      // Upload file
      const form = new FormData();
      form.append("file", this.file);
      const response = await this.$axios({
        method: "post",
        url: `/common/${this.$store.state.charity.id}/profile/logo`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return { fileName: response.fileName, url: response.serveURL };
    },
    async loadImage(file) {
      this.$emit("input", {
        url: URL.createObjectURL(file),
        fileName: this.file.name,
      });
    },
    async clearImage() {
      this.file = null;
      this.$emit("input", {
        url: null,
        fileName: null,
      });
    },
  },
  computed: {
    isValid() {
      return this.required ? [this.$v.requiredIf(!!this.url)] : [];
    },
  },
  watch: {
    value: {
      handler() {
        this.url = this.value.url;
      },
    },
  },
};
</script>
