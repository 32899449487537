<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.personalFundraising.settings.challenges.title") }}
          <v-spacer></v-spacer>

          <v-dialog max-width="600px" v-model="createDialog">
            <template v-slot:activator="{ on }">
              <v-btn raised color="primary" v-on="on">
                {{ $t("admin.personalFundraising.settings.challenges.actions.add") }}
              </v-btn>
            </template>
            <v-card>
              <v-form @submit.prevent="createEvent" ref="createForm" v-model="valid">
                <v-card-title>
                  <span class="headline">{{ $t("admin.personalFundraising.settings.challenges.create.title") }}</span>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    :label="$t('admin.personalFundraising.settings.challenges.create.name')"
                    :rules="[$v.required()]"
                    autocomplete="off"
                    name="newEvent.date"
                    required
                    v-model="newEvent.name"
                  ></v-text-field>
                  <v-menu
                    v-model="menuDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedNewEventDate"
                        :label="$t('admin.personalFundraising.settings.challenges.create.date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="[rules.futureDate]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newEvent.date"
                      @input="menuDatePicker = false"
                      :min="startDate"
                    ></v-date-picker>
                  </v-menu>
                  <v-select
                    v-model="newEvent.idCountry"
                    :label="$t('admin.personalFundraising.settings.challenges.create.country')"
                    :items="toolkit.countries"
                    item-text="label"
                    item-value="id"
                    :rules="[$v.required()]"
                  >
                  </v-select>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!valid" color="primary" type="submit">
                    {{ $t("admin.personalFundraising.settings.challenges.create.submit") }}
                  </v-btn>
                  <v-btn @click="createDialog = false" color="secondary" text>
                    {{ $t("admin.personalFundraising.settings.challenges.create.cancel") }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog max-width="600px" v-model="editDialog">
            <v-card>
              <v-form @submit.prevent="editEvent" ref="editForm" v-model="editValid">
                <v-card-title>
                  <span class="headline">{{ $t("admin.personalFundraising.settings.challenges.edit.title") }}</span>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    :label="$t('admin.personalFundraising.settings.challenges.create.name')"
                    :rules="[$v.required()]"
                    autocomplete="off"
                    required
                    v-model="editedEvent.name"
                  ></v-text-field>
                  <v-menu
                    v-model="menuDatePicker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedEditedEventDate"
                        :label="$t('admin.personalFundraising.settings.challenges.create.date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedEvent.date"
                      @input="menuDatePicker2 = false"
                      :min="startDate"
                    ></v-date-picker>
                  </v-menu>
                  <v-select
                    v-model="editedEvent.idCountry"
                    :label="$t('admin.personalFundraising.settings.challenges.create.country')"
                    :items="toolkit.countries"
                    item-text="label"
                    item-value="id"
                  >
                  </v-select>
                  <v-textarea
                    :label="$t('admin.personalFundraising.settings.challenges.create.description')"
                    autocomplete="off"
                    required
                    outlined
                    v-model="editedEvent.description"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!editValid" color="primary" type="submit">
                    {{ $t("admin.personalFundraising.settings.challenges.create.submit") }}
                  </v-btn>
                  <v-btn @click="editDialog = false" color="secondary" text>
                    {{ $t("admin.personalFundraising.settings.challenges.create.cancel") }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.personalFundraising.settings.challenges.description") }}
        </v-card-subtitle>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="id"
          >
            <template v-slot:item.eventDate="props">
              {{ props.item.eventDate | date }}
            </template>
            <template v-slot:item.idCountry="props">
              {{ toolkit.countries.find((o) => o.id == props.item.idCountry).label }}
            </template>
            <template v-slot:item.published="props">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="isPastDate(props.item.eventDate)" v-on="on">mdi-calendar-remove-outline</v-icon>
                </template>
                <span>{{ $t("admin.personalFundraising.settings.challenges.actions.challenge_ended") }}</span>
              </v-tooltip>
              <v-icon
                color="green"
                v-if="props.item.published && !isPastDate(props.item.eventDate)"
                v-on:click="setPublishedState(props.item, false)"
                >mdi-eye-outline</v-icon
              >
              <v-icon
                color="error"
                v-if="!props.item.published && !isPastDate(props.item.eventDate)"
                v-on:click="setPublishedState(props.item, true)"
                >mdi-eye-off-outline</v-icon
              >
            </template>
            <template v-slot:item.actions="props">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn @click="goToChallengePage(props.item)" class="mx-2" color="primary" icon v-on="on" small>
                    <v-icon dark>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.personalFundraising.settings.challenges.actions.link") }}</span>
              </v-tooltip>
              <v-tooltip left v-if="props.item.owningCharity == $store.state.charity.id">
                <template v-slot:activator="{ on }">
                  <v-btn @click="showEditForm(props.item)" class="mx-2" color="primary" dark fab raised v-on="on" small>
                    <v-icon dark>mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.personalFundraising.settings.challenges.actions.manage") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      menuDatePicker: null,
      menuDatePicker2: null,
      valid: false,
      editValid: false,
      totalItems: 0,
      hasMore: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      loading: true,
      newEvent: {
        idCountry: 74,
      },
      editedEvent: {},
      createDialog: false,
      editDialog: false,
      rules: {
        futureDate: (value) => moment(value).isAfter(moment(), "day") || this.$t("errors.validator.futureDate"),
      },
      toolkit: {},
      startDate: moment().add(1, "d").format("YYYY-MM-DD"),
      headers: [
        { value: "name", text: this.$t("admin.personalFundraising.settings.challenges.headers.name"), sortable: false },
        {
          value: "eventDate",
          text: this.$t("admin.personalFundraising.settings.challenges.headers.date"),
          sortable: false,
        },
        {
          value: "idCountry",
          text: this.$t("admin.personalFundraising.settings.challenges.headers.country"),
          sortable: false,
        },
        {
          value: "published",
          text: this.$t("admin.personalFundraising.settings.challenges.headers.published"),
          sortable: false,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getToolkit();
    // this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async createEvent() {
      await this.$axios.post(`/personal/${this.$store.state.charity.id}/challengePages/create`, {
        event: this.newEvent,
      });
      this.newEvent = {
        idCountry: 74,
      };
      this.createDialog = false;
      this.getDataFromApi();
    },
    async editEvent() {
      await this.$axios.put(`/personal/${this.$store.state.charity.id}/challengePages/${this.editedEvent.eventId}`, {
        event: this.editedEvent,
      });
      this.newEvent = {};
      this.editDialog = false;
    },
    goToChallengePage(item) {
      let url = `${this.$store.state.settings.fundraisingBaseUrl}/charity/${this.$store.state.charity.billingId}/challenge/${item.eventId}`;
      window.open(url);
    },
    async showEditForm(item) {
      item.eventDate = new moment(item.eventDate).format("YYYY-MM-DD");
      this.editedEvent = item;
      this.editDialog = true;
    },
    async getToolkit() {
      this.toolkit = await this.$axios.get("/common/toolkit");
      this.toolkit.countries.map((r) => (r.label = this.$t(r.labelKey)));
      this.toolkit.eventTypes.map((r) => (r.label = this.$t(r.labelKey)));
    },
    async getDataFromApi() {
      this.loading = true;
      const result = await this.$axios.get(`/personal/${this.$store.state.charity.id}/challengePages`, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
        },
      });
      this.items = result.rows;
      this.totalItems = result.count;

      this.loading = false;
    },
    async setPublishedState(item, state) {
      await this.$axios.put(
        `/personal/${this.$store.state.charity.id}/challengePages/${item.eventId}/publishStatus/${state}`
      );
      item.published = state;
    },
    isPastDate(date) {
      return new moment(date).isBefore(new moment());
    },
  },
  computed: {
    formattedNewEventDate: {
      get() {
        if (!this.newEvent.date) return null;
        return moment(this.newEvent.date).format("DD/MM/YYYY");
      },
      set(date) {
        const parsedDate = moment(date, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          this.newEvent.date = parsedDate;
        }
      },
    },
    formattedEditedEventDate: {
      get() {
        if (!this.editedEvent.date) return null;
        return moment(this.editedEvent.date).format("DD/MM/YYYY");
      },
      set(date) {
        const parsedDate = moment(date, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          this.editedEvent.date = parsedDate;
        }
      },
    },
  },
};
</script>

<style>
.v-data-footer__select {
  display: none;
}
</style>
