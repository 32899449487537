<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.eventFundraising.groups.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.eventFundraising.groups.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" raised @click="exportDownload()">
            {{ $t("admin.eventFundraising.groups.actions.export") }}
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.eventFundraising.groups.description") }}
        </v-card-subtitle>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.imageUrl="props">
            <v-img :src="props.item.imageUrl" height="30px" width="30px" contain></v-img>
          </template>
          <template v-slot:item.lastName="props"> {{ props.item.firstName }} {{ props.item.lastName }} </template>
          <template v-slot:item.creationDate="props">
            {{ props.item.creationDate | date }}
          </template>
          <template v-slot:item.title="props">
            <a target="_blank" :href="props.item.actionHomeUrl">{{ props.item.title }}</a>
          </template>
          <template v-slot:item.name="props">
            <a target="_blank" :href="props.item.groupUrl">{{ props.item.name }}</a>
          </template>
          <template v-slot:item.raised="props">
            {{ props.item.raised | amount("EUR") }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
const fileDownload = require("js-file-download");

export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      pagination: {
        itemsPerPage: 10,
      },
      search: "",
      headers: [
        { value: "imageUrl", sortable: false },
        { text: this.$t("admin.eventFundraising.groups.list.name"), value: "lastName" },
        { text: this.$t("admin.eventFundraising.groups.list.group"), value: "name" },
        {
          text: this.$t("admin.eventFundraising.groups.list.company"),
          value: "companyName",
        },
        {
          text: this.$t("admin.eventFundraising.groups.list.members"),
          value: "members",
        },
        {
          text: this.$t("admin.eventFundraising.groups.list.raisedAmount"),
          value: "raised",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async exportDownload() {
      this.$axios({
        url: `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/groups/excelReport`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        fileDownload(response, `${this.$i18n.t("admin.eventFundraising.groups.exportFileName")}.xls`);
      });
    },
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/groups`,
        {
          params: {
            page: page - 1,
            size: itemsPerPage,
            sortBy: sortBy.join(","),
            sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
            search: this.search || undefined,
          },
        }
      );
      this.items = result.rows;
      this.totalItems = result.count;
      this.loading = false;
    },
  },
};
</script>
<style>
.v-data-footer__select {
  display: none;
}
</style>
