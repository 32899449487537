<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.donationForm.forms.dashboard.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.donationForm.forms.dashboard.description") }}
        </v-card-subtitle>
        <v-row class="px-4">
          <v-col md="2">
            <v-select
              v-model="selectedYear"
              :items="years"
              :label="$t('prop.dateranges.year')"
              :hideDetails="true"
              @change="getDataFromApi"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="px-4">
          <v-col>
            <v-card :disabled="loading" flat :loading="loading ? '#fb2965' : false">
              <v-card-title class="justify-center"
                >{{ $t("admin.donationForm.forms.dashboard.donations") }}:
                {{ data.donations.amount | amount("EUR") }}</v-card-title
              >
              <div class="no-data" v-if="!(data.donations.amount > 0)">
                <span>{{ $t("admin.donationForm.forms.dashboard.no_donation_data") }}</span>
              </div>
              <bar-chart :chart-data="donationsChart" :options="chartOptionsDonations"></bar-chart>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
const moment = require("moment");
import BarChart from "../../../../plugins/BarChart.js";

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      data: {
        donations: {},
      },
      donationsChart: {},
      chartOptionsDonations: {
        tooltips: {
          callbacks: {},
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                autoSkip: false,
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              distribution: "linear",
              ticks: {
                major: {
                  enabled: true,
                  fontStyle: "bold",
                },
                source: "auto",
                autoSkip: true,
                autoSkipPadding: 75,
                maxRotation: 0,
                sampleSize: 100,
              },
            },
          ],
          scaleBeginAtZero: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      loading: false,
      selectedYear: moment().year(),
      years: [],
    };
  },
  mounted() {
    this.chartOptionsDonations.scales.yAxes[0].ticks.callback = (v) => this.$options.filters.amount(v, "€");
    this.chartOptionsDonations.tooltips.callbacks.title = (v) => this.$options.filters.weekRange(v[0].label);
    this.chartOptionsDonations.tooltips.callbacks.label = (v) =>
      this.$t("admin.donationForm.forms.dashboard.donations") + ": " + this.$options.filters.amount(v.value, "€");
    this.getYearsList();
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const result = await this.$axios.get(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/dashboard/${moment()
          .year(this.selectedYear)
          .startOf("year")
          .format("YYYY-MM-DD")}/${moment().year(this.selectedYear).endOf("year").format("YYYY-MM-DD")}`
      );
      this.data = result;

      this.donationsChart = {
        labels: result.donations.data.labels,
        datasets: [
          {
            label: "Donations",
            backgroundColor: "#f7bfd0",
            borderColor: "#fb2965",
            data: result.donations.data.data,
          },
        ],
      };
      this.loading = false;
    },
    async getYearsList() {
      const charity = await this.$axios.get(`/common/charity/${this.$store.state.charity.id}`);
      const creationYear = moment(charity.creationDate).year();
      let year = moment().year();
      while (creationYear != year) {
        this.years.push(year);
        year--;
      }
      this.years.push(creationYear);
    },
  },
};
</script>
<style>
.no-data {
  position: absolute;
  height: 400px;
  line-height: 400px;
  width: 100%;
  text-align: center;
  background: #4c4b4b85;
}
.no-data span {
  background: #fff;
  padding: 1.5em;
}
</style>
