<template>
  <div class="fill-height">
    <v-navigation-drawer v-model="drawer" fixed app width="300" v-if="showMenu">
      <v-slide-x-transition mode="out-in">
        <router-view name="menu"></router-view>
      </v-slide-x-transition>
    </v-navigation-drawer>
    <v-app-bar color="#F6F8F9" fixed app dense class="header-toolbar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="showMenu" v-show="$vuetify.breakpoint.mdAndDown" />
      <img
        v-if="$store.state.charity"
        :src="$store.state.charity.logoUrl"
        :title="$store.state.charity.name"
        :alt="$store.state.charity.name"
        height="48px"
      />
      <v-spacer></v-spacer>
      <img src="/assets/logo.svg" height="26px" />
      <img v-show="$vuetify.breakpoint.mdAndUp" src="/assets/alvarum.svg" height="20px" style="margin-left: 20px" />
      <v-spacer></v-spacer>
      <lang-switcher />
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <a v-on="on" class="mt-1">
            <img height="40px" src="/assets/user.png" />
          </a>
        </template>
        <v-list dense>
          <v-list-item :href="$store.state.settings.fundraisingBaseUrl + '/myProfile'" ripple color="primary">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("admin.menu.back_to_myaccount") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="$route.params.charityId ? '/my/' + $route.params.charityId + '/applications' : '/my/applications'"
            ripple
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("admin.menu.back_to_apps") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :href="$store.state.settings.fundraisingBaseUrl + '/user' + $store.state.me.id + '/edit'"
            ripple
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("admin.menu.user_settings") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="disconnect" ripple color="primary">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("admin.menu.signout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main app class="fill-height">
      <v-slide-x-reverse-transition mode="out-in">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-slide-x-reverse-transition>
    </v-main>
  </div>
</template>

<script>
import LangSwitcher from "../../common/_langSwitcher";

export default {
  components: { LangSwitcher },
  data() {
    return {
      drawer: null,
      accounts: [],
      showMenu: true,
    };
  },
  computed: {},
  async updated() {
    if (this.$route.matched[0].path == "" || this.$route.matched[0].path == "/my") {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
  },
  methods: {
    async disconnect() {
      await this.$store.dispatch("AUTHENTICATE", { auth: false });
      let returnUrl = `${this.$store.state.settings.fundraisingBaseUrl}/deconnexion`;
      window.location.href = this.$store.state.settings.casBaseUrl + `/logout?service=${encodeURIComponent(returnUrl)}`;
    },
  },
};
</script>

<style scoped>
.snackstack {
  top: 65px;
  position: fixed;
  z-index: 1111;
  width: 100%;
}

.snackstack .v-snack {
  position: relative;
  margin-top: 6px;
}

.las {
  font-size: 24px;
}
</style>
