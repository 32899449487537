<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.users.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.users.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-card></v-card>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.simulate="props">
            <a :href="simulateUserUrl(props.item)">
              {{ $t("admin.superadmin.users.actions.simulate") }}
            </a>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "../../../../util/debounce";

export default {
  data() {
    return {
      search: "",
      pagination: {
        itemsPerPage: 10,
      },
      totalItems: 0,
      items: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.users.list.user_id"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.users.list.email"),
          value: "login",
          sortable: false,
        },
        { value: "simulate", sortable: false },
      ],
    };
  },
  mounted() {
    // this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
    },
  },
  methods: {
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/users`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
        },
      });
      this.items = result.users.rows;
      this.totalItems = result.users.count;
      this.loading = false;
    },
    simulateUserUrl(user) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/simulateuser?userId=${user.id}`;
    },
  },
};
</script>

<style></style>
