<template>
  <v-container>
    <v-row>
      <v-col sm="8">
        <h2>{{ $t("appSelector.title") }} {{ charity.title }}</h2>
        <div>
          {{ $store.state.me.userName }} {{ $store.state.me.login }}
          <a :href="myAccount.url">{{ $t("appSelector.goToProfile") }} </a>
        </div>
        <v-card color="#D7FAF0" class="my-6" v-show="showCongrats">
          <v-card-title class="secondary--text">
            <i class="las la-clipboard-check"></i>
            <v-spacer></v-spacer>
            <i @click="hideCongrats()" class="las la-times" style="cursor: pointer"></i>
          </v-card-title>
          <v-card-title class="font-weight-medium secondary--text">
            {{
              $t("appSelector.congratsMsg", { name: $store.state.me.userName, charityName: charity.name })
            }}</v-card-title
          >
          <v-card-title><v-divider color="#1fcc9c"></v-divider></v-card-title>
          <v-card-actions>
            <v-btn color="secondary" :href="charity.portalUrl">{{ $t("appSelector.goToMyPage") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="4">
        <h2 class="my-3" v-show="notifications.length > 0">{{ $t("appSelector.notificationsTitle") }}</h2>
        <v-card v-show="notifications.length > 0">
          <v-card-title>
            <v-icon style="font-size: 48px" class="mr-1">mdi-clipboard-list-outline</v-icon>
          </v-card-title>
          <v-list>
            <template v-for="(notification, index) in notifications">
              <v-list-item :key="notification.id">
                <v-list-item-content>
                  <a :href="notification.url" class="big_link">
                    {{ $t(`appSelector.notifications.${notification.id}.title`) }}
                  </a>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon @click="hideNotification(notification)">mdi-close</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index != notifications.length - 1" :key="`divider${index}`"> </v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>{{ $t("appSelector.applicationsTitle") }}</h2>
        <div v-if="charity.name">
          {{ charity.name }}
          <a :href="charity.portalUrl" v-if="charity.portalUrl"> {{ $t("appSelector.goToFundraisingPortal") }} </a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" lg="4" v-for="application in knownApps" :key="application.id">
        <v-card :href="application.url" :color="appData[application.id].background" class="app-card">
          <v-img
            :src="appData[application.id].bgimage"
            :gradient="`to bottom, ${appData[application.id].background}A0 0%, ${
              appData[application.id].background
            }E0 50%, ${appData[application.id].background}F0 100%`"
            light
            height="200px"
          >
            <v-card-title class="flex-nowrap">
              <v-icon :color="appData[application.id].color" size="72px">
                {{ appData[application.id].icon }}
              </v-icon>
            </v-card-title>
            <v-card-title
              class="flex-nowrap pb-0 font-weight-medium"
              :style="`color: ${appData[application.id].color}`"
            >
              {{ $t(`appSelector.applications.${application.id}.title`) }}
            </v-card-title>
            <v-card-text :style="`color: ${appData[application.id].color}`" class="caption">
              {{ $t(`appSelector.applications.${application.id}.description`) }}
            </v-card-text>
          </v-img>
          <v-speed-dial v-if="application.id == 'DonationForms'" absolute top right>
            <template v-slot:activator>
              <v-btn color="primary" tile elevation="0" small>
                <span class="text-h6 font-weight-bold">{{ $t("prop.betaTag") }}</span>
              </v-btn>
            </template>
          </v-speed-dial>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      applications: [],
      notifications: [],
      myAccount: {},
      showCongrats: false,
      appData: {
        //         MyAccount: {
        //           icon: "la-globe",
        //           background: "#C5C9F4",
        // //          bgimage: "/assets/app_myaccount.png",
        //           color: "#3545EE"
        //         },
        SuperAdminBackOffice: {
          icon: "la-hand-holding-heart",
          background: "#F6A5A8",
          //          bgimage: "/assets/app_personalfundraising.jpg",
          color: "#d91202",
        },
        AlvarumPaymentPaymentAccount: {
          icon: "la-wallet",
          background: "#C5C9F4", // "#ebeded", // "#ECD7A6",
          //          bgimage: "/assets/app_finance.jpg",
          color: "#3545EE",
        },
        AlvarumPayment: {
          icon: "la-wallet",
          background: "#F6A5A8", // "#F2B093",
          //          bgimage: "/assets/app_finance.jpg",
          color: "#d91202",
        },
        CharityProfileBackoffice: {
          icon: "la-cog",
          background: "#C5C9F4", // "#ebeded", // "#F0F0F0"
          //          bgimage: "/assets/app_profileadmin.jpg",
          color: "#3545EE",
        },
        FundraisingBackOffice: {
          icon: "la-calendar",
          background: "#C5C9F4", // "#ebeded", // "#ABC8A9"
          //          bgimage: "/assets/app_eventfundraising.jpg",
          color: "#3545EE",
        },
        DonationForms: {
          icon: "la-wpforms",
          background: "#C5C9F4", // "#ebeded", // "#ABC8A9"
          //          bgimage: "/assets/app_eventfundraising.jpg",
          color: "#3545EE",
        },
        FundraisingBackOfficePersonal: {
          icon: "la-hand-holding-heart",
          background: "#C5C9F4", // "#ebeded", // "#E7D7D1"
          //          bgimage: "/assets/app_personalfundraising.jpg",
          color: "#3545EE",
        },
        FundraisingBackOfficeMarketing: {
          icon: "la-cloud-download-alt",
          background: "#C5C9F4", // "#ebeded", // "#8FA0A8"
          //          bgimage: "/assets/app_marketingexports.jpg",
          color: "#3545EE",
        },
      },
    };
  },
  computed: {
    knownApps() {
      return this.applications.filter((a) => this.appData[a.id]);
    },
    charity() {
      return this.$store.state.charity;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let url = "/appSelector/applications/";
      if (this.charity.id) {
        url += this.charity.id;
      }

      let result = await this.$axios.get(url);
      this.applications = result.applications;
      if (result.notifications) {
        result.notifications.forEach((element) => {
          if (!localStorage.getItem(`notification_${this.charity.id}_${element.id}`)) {
            this.notifications.push(element);
          }
        });
      }
      this.myAccount = this.applications.find((e) => e.id == "MyAccount");
      this.showCongrats = result.notifications && !!result.notifications.length;
      if (localStorage.getItem(`notification_${this.charity.id}_congrats`)) {
        this.showCongrats = false;
      }
    },
    hideNotification(notification) {
      this.notifications = this.notifications.filter((item) => item !== notification);
      localStorage.setItem(`notification_${this.charity.id}_${notification.id}`, true);
    },
    hideCongrats() {
      localStorage.setItem(`notification_${this.charity.id}_congrats`, true);
      this.showCongrats = false;
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/components/VStepper/_variables.scss";

.las {
  font-size: 32px;
}
.las.small {
  font-size: 24px;
}

main {
  background: #fbfbfd;
}

.v-application a.big_link {
  text-decoration: none;
  color: #000000;
  font-size: 20px;
}

.app-card:hover {
  opacity: 0.75;
}
</style>
