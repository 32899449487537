<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.eventFundraising.settings.campaignPage.title") }}
          <v-btn v-if="url" icon :href="url" target="_blank" color="primary" :title="$t('admin.menu.go_to_landing')">
            <v-icon>mdi-launch</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.eventFundraising.settings.campaignPage.titleDescription") }}
        </v-card-subtitle>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <quill-editor
                  v-model="challengePage.description"
                  :options="{
                    modules: {
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                        [{ align: ['', 'center', 'right'] }],
                        ['link'],
                        ['clean'],
                      ],
                    },
                    placeholder: $t('admin.eventFundraising.settings.campaignPage.description'),
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="4" lg="3">
                <v-text-field
                  v-model="challengePage.targetAmount"
                  :label="$t('admin.eventFundraising.settings.fundraising.target')"
                  type="number"
                  :min="1"
                  ref="amount"
                  :rules="[$v.min(0)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="published"
                  :label="$t('admin.eventFundraising.settings.campaignPage.published')"
                ></v-checkbox>
                <v-checkbox
                  v-model="challengePage.enableGroupPages"
                  :label="$t('admin.eventFundraising.settings.campaignPage.enableGroupPages')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn raised color="primary" v-on:click="save()" :disabled="!valid">{{
            $t("admin.eventFundraising.settings.eventProfile.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      valid: false,
      challengePage: {},
      published: false,
      url: "",
    };
  },
  mounted() {
    this.getDataFromApi();

    this.url =
      this.$store.state.settings.fundraisingBaseUrl +
      `/charity/${this.$route.params.charityId}/challenge/${this.$route.params.eventId}`;
  },
  methods: {
    async getDataFromApi() {
      let result2 = await this.$axios.get(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/challengePage`
      );
      this.challengePage = result2.challengePage;
      this.published = result2.published;
    },
    async save() {
      this.challengePage.published = this.published;
      await this.$axios.put(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/challengePage`,
        this.challengePage
      );
      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.branding.msg_saved"));
    },
  },
};
</script>

<style></style>
