<template>
  <div>
    <v-tooltip v-if="value" left>
      <template v-slot:activator="{ on }">
        <v-icon color="success" v-on="on">mdi-check-bold</v-icon>
      </template>
      <span>{{ $t("prop.yes") }}</span>
    </v-tooltip>
    <v-tooltip v-else left>
      <template v-slot:activator="{ on }">
        <v-icon color="error" v-on="on">mdi-close</v-icon>
      </template>
      <span>{{ $t("prop.no") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "YesNoIcon",
  props: {
    value: Boolean,
  },
};
</script>
