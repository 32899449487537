<template>
  <v-row justify="center">
    <v-col xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.eventFundraising.coachingEmails.title") }}
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.eventFundraising.coachingEmails.description") }}
        </v-card-subtitle>
        <v-container>
          <v-switch
            v-model="emailsEnabled"
            @change="saveEmailsEnabled()"
            :label="$t('admin.eventFundraising.coachingEmails.enableCoaching')"
          ></v-switch>
          <v-divider light class="mt-2"></v-divider>
          <v-row>
            <v-col lg="10" xl="8">
              <v-list :style="{ opacity: opacity }">
                <v-list-item v-for="email in emails" :key="email.name">
                  <v-list-item-content>
                    <v-list-item-title>{{ email.title }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ email.subTitle }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-divider light class="mt-3"></v-divider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-btn fab small color="primary" @click="showPreview(email)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-dialog v-model="preview" scrollable max-width="800" height="100%">
            <v-card>
              <v-app-bar elevation="1" color="white">
                <v-toolbar-title>{{ selectedEmailTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="preview = false" icon>
                  <v-icon large color="black">mdi-close</v-icon>
                </v-btn>
              </v-app-bar>
              <v-card-text class="pa-0">
                <v-container class="pa-0">
                  <iframe
                    width="100%"
                    height="100%"
                    :srcdoc="previewHtml"
                    ref="iframe"
                    id="iframe1"
                    scrolling="no"
                  ></iframe>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      preview: false,
      previewHtml: null,
      emailsEnabled: true,
      selectedEmailTitle: "",
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async showPreview(email) {
      this.$store.commit("LOADING", true);
      this.selectedEmailTitle = email.title;
      this.previewHtml = await this.$axios.get(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/coaching/${email.name}`
      );
      this.preview = true;
      this.$nextTick(() => {
        let iFrame = this.$refs.iframe;
        setTimeout(function () {
          iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
        }, 100);
      });

      this.$store.commit("LOADING", false);
    },
    async saveEmailsEnabled() {
      await this.$axios.patch(`/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/coaching/`, {
        emailsEnabled: this.emailsEnabled,
      });
      if (this.emailsEnabled) {
        this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.coachingEmails.enabled"));
      } else {
        this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.coachingEmails.disabled"));
      }
    },
    async loadData() {
      this.emailsEnabled = await this.$axios.get(
        `/admin/${this.$store.state.charity.id}/events/${this.$route.params.eventId}/coaching/`
      );
    },
  },
  computed: {
    opacity() {
      return this.emailsEnabled ? 1 : 0.4;
    },
    emails() {
      return [
        {
          name: `coaching-01-personnalize-page-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.personalizePageTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.personalizePageSubTitle"),
        },
        {
          name: `coaching-02-first-donation-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.firstDonationTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.firstDonationSubTitle"),
        },
        {
          name: `coaching-03-basic-advice-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.basicAdviceTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.basicAdviceSubTitle"),
        },
        {
          name: `coaching-04-spread-the-word-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.spreadTheWordTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.spreadTheWordSubTitle"),
        },
        {
          name: `coaching-05-publish-updates-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.publishUpdatesTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.publishUpdatesSubTitle"),
        },
        {
          name: `coaching-06-ask-your-boss-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.askYourBossTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.askYourBossSubTitle"),
        },
        {
          name: `coaching-07-fundraising-summary-update-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.fundraisingSummaryUpdateTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.fundraisingSummaryUpdateSubTitle"),
        },
        {
          name: `coaching-08-before-event-date-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.beforeEventDateTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.beforeEventDateSubTitle"),
        },
        {
          name: `coaching-end-09-page-closing-7-days-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.pageClosing7DaysTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.pageClosing7DaysSubTitle"),
        },
        {
          name: `coaching-end-10-page-closing-tomorrow-${this.$i18n.locale}`,
          title: this.$t("admin.eventFundraising.coachingEmails.pageClosingTomorrowTitle"),
          subTitle: this.$t("admin.eventFundraising.coachingEmails.pageClosingTomorrowSubTitle"),
        },
      ];
    },
  },
};
</script>

<style scoped>
iframe {
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
</style>
