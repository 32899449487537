<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.charity.contact.title") }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t("admin.charity.contact.subtitle") }}
        </v-card-subtitle>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('admin.charity.contact.email')"
                    :rules="[$v.isEmail()]"
                    name="contact.email"
                    v-model="charityProfile.contactEmail"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('admin.charity.contact.phone_number')"
                    name="contact.phone_number"
                    v-model="charityProfile.contactPhone"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('admin.charity.contact.website')"
                    :rules="[$v.isUrl()]"
                    name="contact.website"
                    v-model="charityProfile.externalWebsite"
                    clearable
                    v-on:blur="prefixHttp"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer />
          <v-btn raised color="primary" v-on:click="save()" :disabled="!valid">{{
            $t("admin.charity.profile.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      charityProfile: {
        externalWebsite: "",
      },
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.charityProfile = await this.$axios.get(`/charity/${this.$store.state.charity.id}/profile`);
    },
    async save() {
      await this.$axios.put(`/charity/${this.$store.state.charity.id}/profile`, this.charityProfile);

      this.$store.commit("SUCCESS", this.$t("admin.charity.profile.msg_saved"));
    },
    prefixHttp() {
      if (
        this.valid &&
        this.charityProfile.externalWebsite &&
        !this.charityProfile.externalWebsite.startsWith("http")
      ) {
        this.charityProfile.externalWebsite = "http://" + this.charityProfile.externalWebsite;
      }
    },
  },
};
</script>

<style></style>
