<template>
  <div>
    <PageCard></PageCard>
    <v-list>
      <v-list-item :to="`/superadmin/dashboard`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/transactions`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"
      >
        <v-list-item-icon>
          <v-icon>mdi-handshake</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.transactions") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/fundraisingPages`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"
      >
        <v-list-item-icon>
          <v-icon>mdi-text-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.fundraisingPages") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/groupsList`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"
      >
        <v-list-item-icon>
          <v-icon>mdi-view-list </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.groupsList") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/users`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.users") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/events`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"
      >
        <v-list-item-icon>
          <v-icon>mdi-calendar-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.events") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/charities`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')"
      >
        <v-list-item-icon>
          <v-icon>mdi-charity</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.charities") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/superadmin/superadmins`"
        ripple
        color="primary"
        v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_ADMIN')"
      >
        <v-list-item-icon>
          <v-icon>mdi-head-snowflake</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.superadmin.superadmins") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PageCard from "../../_menu.context.selector.vue";

export default {
  components: {
    PageCard,
  },
  methods: {
    hasRole(role) {
      return (
        this.$store.state.me.userRoles.indexOf(role) !== -1 ||
        this.$store.state.me.userRoles.indexOf("ROLE_FUNDRAISING_APPLICATIONOWNER_ADMIN") !== -1
      );
    },
  },
};
</script>
