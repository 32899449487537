<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.donationForm.settings.notifications.title") }}
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="createNew">{{
            $t("admin.donationForm.settings.notifications.createNew")
          }}</v-btn>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.donationForm.settings.notifications.description") }}
        </v-card-subtitle>
        <v-container>
          <v-card v-if="isNotificationsEmpty" class="d-flex align-center justify-center pa-2 mx-auto" outlined>
            <div class="font-weight-thin">
              {{ $t("admin.donationForm.settings.notifications.noNotificationsText") }}
            </div>
          </v-card>
          <v-list v-else>
            <v-list-item v-for="notification in notifications" :key="`notification-${notification.id}`">
              <v-list-item-content>
                <v-list-item-title>
                  {{ notification.email }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon small>mdi-bell-outline</v-icon
                  >{{ $t(`admin.donationForm.settings.notifications.donationCreated`) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-divider light class="mt-3"></v-divider>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2" dark fab raised small v-on="on" color="primary" @click="edit(notification)">
                    <v-icon>mdi-clipboard-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.donationForm.settings.notifications.actions.edit") }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    dark
                    fab
                    raised
                    small
                    v-on="on"
                    color="error"
                    @click="removeNotification(notification)"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.donationForm.settings.notifications.actions.delete") }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>

          <v-dialog v-model="editDialog" max-width="60%" :width="$vuetify.breakpoint.mdAndUp ? '600px' : '900px'">
            <v-card>
              <v-card-title>{{ $t("admin.donationForm.settings.notifications.edit") }}</v-card-title>
              <v-card-subtitle class="text-caption">
                {{ $t("admin.donationForm.settings.notifications.emailHint") }}
              </v-card-subtitle>
              <v-card-text>
                <v-form
                  @submit.prevent="saveNotification"
                  ref="notificationsForm"
                  id="notificationsForm"
                  v-model="notificationFormValid"
                  lazy-validation
                >
                  <v-text-field
                    class="px-3"
                    prepend-icon="mdi-email-outline"
                    v-model="editNotification.email"
                    :label="$t('admin.donationForm.settings.notifications.email')"
                    :rules="[$v.required(), $v.isEmail()]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="editDialog = false">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn raised type="submit" color="primary" form="notificationsForm">
                  {{ $t("submit") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const DONATION_CREATED = "DONATION_CREATED";

export default {
  data() {
    return {
      editDialog: false,
      editMode: false,
      notificationFormValid: false,

      editNotification: {
        email: "",
        type: DONATION_CREATED,
      },
      notifications: {},
    };
  },
  computed: {
    isNotificationsEmpty: function () {
      return this.notifications && Object.keys(this.notifications).length === 0;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.notifications = await this.$axios.get(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/notifications`
      );
    },
    createNew() {
      if (this.$refs.notificationsForm != undefined) this.$refs.notificationsForm.resetValidation();
      this.editNotification = {
        email: "",
        type: DONATION_CREATED,
      };
      this.editMode = false;
      this.editDialog = true;
    },
    edit(item) {
      this.editMode = true;
      this.editNotification = {
        email: item.email,
        type: item.type,
      };
      this.editDialog = true;
    },
    async saveNotification() {
      if (!this.$refs.notificationsForm.validate()) return;

      const index = this.ifEmailExistsGetElementIndex(this.editNotification.email);
      if (this.editMode || index != -1) this.removeNotification(this.notifications[index], false);

      await this.$axios.post(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/notification`,
        this.editNotification
      );

      if (this.editMode)
        this.$store.commit("SUCCESS", this.$t("admin.donationForm.settings.notifications.msg_updated"));
      else this.$store.commit("SUCCESS", this.$t("admin.donationForm.settings.notifications.msg_created"));

      this.getDataFromApi();
      this.editDialog = false;
    },
    async removeNotification(notification, getData = true) {
      await this.$axios.delete(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/notification/${notification.id}`
      );

      if (!this.editMode)
        this.$store.commit("SUCCESS", this.$t("admin.donationForm.settings.notifications.msg_removed"));
      if (getData) this.getDataFromApi();
    },
    ifEmailExistsGetElementIndex(email) {
      return this.notifications.findIndex((el) => el.email === email);
    },
  },
};
</script>

<style>
.amount {
  margin-left: 1em;
}
</style>
