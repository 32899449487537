<template>
  <div id="zsiqwidget"></div>
</template>

<script>
export default {
  data() {
    return {
      helpChat: null,
    };
  },
  mounted() {
    this.loadHelpChat();
  },
  methods: {
    loadHelpChat() {
      if (this.$store.state.settings.helpChatWidgetCode) {
        const $zoho = (window["$zoho"] = window["$zoho"] || {});
        this.$data.helpChat = $zoho.salesiq = $zoho.salesiq || {
          widgetcode: this.$store.state.settings.helpChatWidgetCode,
          values: {},
          ready: () => {
            if (this.$store.state.me) {
              this.$data.helpChat.visitor.name(this.$store.state.me.userName);
              this.$data.helpChat.visitor.email(this.$store.state.me.login);
            }
          },
        };
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "zsiqscript";
        script.defer = true;
        script.src = "https://salesiq.zoho.eu/widget";
        const latestScript = document.getElementsByTagName("script")[0];
        latestScript.parentNode.insertBefore(script, latestScript);
        setTimeout(function () {
          if (document.getElementsByClassName("zsiq_floatmain").length) {
            document.getElementsByClassName("zsiq_floatmain")[0].className += " zsiqwidget_minimized";
          }
        }, 10000);
      }
    },
  },
};
</script>

<style>
.zsiq_floatmain,
#titlediv {
  transition: all ease-in-out 300ms;
}
.zsiq_floatmain {
  transform: scale(0.9);
}
.zsiqwidget_minimized:not(:hover) {
  transform: scale(0.7);
  right: 0;
  bottom: 0;
}
.zsiqwidget_minimized:not(:hover) #titlediv {
  transform: scale(0);
}
.zsiq_theme1 .siqico-chat::before {
  content: "" !important;
}
.zsiq_theme1 .siqico-chat {
  background-image: url(/assets/help.png);
  background-size: 20px 36px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
