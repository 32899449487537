var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xl":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.customExport.title"))+" "),_c('v-spacer')],1),_c('v-card-subtitle',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.customExport.titleDescription"))+" ")]),_c('v-card-content',[_c('v-row',[_c('v-col',{attrs:{"lg":"8","xl":"6"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("admin.marketingExport.fundraisers.title")))]),_c('v-list-item-subtitle',[_c('v-divider',{staticClass:"mt-3",attrs:{"light":""}})],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.export"))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-download")])],1)]}}]),model:{value:(_vm.fundraisersExportMenu),callback:function ($$v) {_vm.fundraisersExportMenu=$$v},expression:"fundraisersExportMenu"}},[_c('v-card',[_c('PeriodPicker',{model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.fundraisersExportMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","raised":""},on:{"click":function($event){return _vm.exportFundraisersDownload()}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.export"))+" ")])],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("admin.marketingExport.donors.title")))]),_c('v-list-item-subtitle',[_c('v-divider',{staticClass:"mt-3",attrs:{"light":""}})],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.donors.actions.export"))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-download")])],1)]}}]),model:{value:(_vm.donorsExportMenu),callback:function ($$v) {_vm.donorsExportMenu=$$v},expression:"donorsExportMenu"}},[_c('v-card',[_c('PeriodPicker',{model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.donorsExportMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.donors.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","raised":""},on:{"click":function($event){return _vm.exportDonorsDownload()}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.donors.actions.export"))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"8","xl":"6"}},[_c('v-list',[(!_vm.items || !_vm.items.length)?[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.customExport.noItems"))+" "),_c('a',{attrs:{"href":"https://alvarum.desk.com/customer/en/portal/emails/new"}},[_vm._v(_vm._s(_vm.$t("admin.marketingExport.customExport.contactUs")))])])]:[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.customExport.description"))+" ")])],_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:item.exportType},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_c('v-divider',{staticClass:"mt-3",attrs:{"light":""}})],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.export"))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-download")])],1)]}}],null,true),model:{value:(_vm.additionalExportMenus[index]),callback:function ($$v) {_vm.$set(_vm.additionalExportMenus, index, $$v)},expression:"additionalExportMenus[index]"}},[_c('v-card',[_c('PeriodPicker',{model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.additionalExportMenus.splice(index, 1, false)}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","raised":""},on:{"click":function($event){return _vm.exportDownload(item)}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.export"))+" ")])],1)],1)],1)],1)}),(_vm.$store.state.charity.id == 71)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("admin.marketingExport.customExport.csvReintegrationpurposes71")))]),_c('v-list-item-subtitle',[_c('v-divider',{staticClass:"mt-3",attrs:{"light":""}})],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.export"))+" "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-download")])],1)]}}],null,false,3655818865),model:{value:(_vm.reintegrationPurposes71Menu),callback:function ($$v) {_vm.reintegrationPurposes71Menu=$$v},expression:"reintegrationPurposes71Menu"}},[_c('v-card',[_c('PeriodPicker',{model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.reintegrationPurposes71Menu = false}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","raised":""},on:{"click":function($event){return _vm.exportDownloadReintegrationPurposes71()}}},[_vm._v(" "+_vm._s(_vm.$t("admin.marketingExport.fundraisers.actions.export"))+" ")])],1)],1)],1)],1):_vm._e()],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }