<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.charities.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.charities.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark :href="`${$store.state.settings.fundraisingBaseUrl}/createACharityProfile`">
            {{ $t("admin.superadmin.charities.actions.create") }}
          </v-btn>
          <v-card></v-card>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.logo="props">
            <v-img
              class="ma-2"
              :src="props.item.charityProfile.logoUrl"
              max-height="80px"
              max-width="80px"
              contain
            ></v-img>
          </template>
          <template v-slot:item.country="props">
            <span>
              {{ $t(props.item.charityProfile.country.labelKey) }}
            </span>
          </template>
          <template v-slot:item.deactivated="props">
            <YesNoIcon :value="props.item.deactivated ? false : true"></YesNoIcon>
          </template>
          <template v-slot:item.customTip="props">
            <YesNoIcon :value="props.item.customTip ? true : false"></YesNoIcon>
          </template>
          <template v-slot:item.actions="props">
            <div class="charity-list-action-buttons-field">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn @click="showDetails(props.item)" class="mx-2" color="primary" fab raised small v-on="on">
                    <v-icon dark>mdi-newspaper-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.superadmin.charities.actions.details") }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :href="'/my/' + props.item.billingId + '/applications'"
                    class="mx-2"
                    color="primary"
                    fab
                    raised
                    small
                    v-on="on"
                  >
                    <v-icon dark>mdi-store</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.superadmin.charities.actions.backoffice") }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="detailsDialog" max-width="600">
          <v-card>
            <v-card-title>
              {{ $t("admin.superadmin.charities.actions.details") }}
            </v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("admin.superadmin.charities.details.charity_profile") }}:
                        </v-list-item-title>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold">{{ $t("admin.superadmin.charities.details.name") }}: </span>
                          <span>{{ detailsToDisplay.name }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold">{{ $t("admin.superadmin.charities.details.country") }}: </span>
                          <span>{{ detailsToDisplay.country }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.charities.details.published") }}:
                          </span>
                          <span>{{ detailsToDisplay.published ? "Yes" : "No" }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="detailsToDisplay.fundraisingActivated">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("admin.superadmin.charities.details.charity_informations") }}:
                        </v-list-item-title>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.charities.details.billing_id") }}:
                          </span>
                          <span>{{ detailsToDisplay.billingId }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="detailsToDisplay.admin" class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.charities.details.admin_name") }}:
                          </span>
                          <span>{{ detailsToDisplay.admin.userProfile.userName }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="detailsToDisplay.admin" class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.charities.details.admin_login") }}:
                          </span>
                          <span>{{ detailsToDisplay.admin.login }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mx-5" v-if="detailsToDisplay.charityActive">
                          <span class="font-weight-bold">
                            <a :href="detailsToDisplay.charityUrl">
                              {{ $t("admin.superadmin.charities.actions.charityPortal") }}
                            </a>
                          </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold">
                            <a :href="detailsToDisplay.charitySettingsUrl">
                              {{ $t("admin.superadmin.charities.actions.charitySettings") }}
                            </a>
                          </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="selectedCharityOwner" class="mx-5">
                          <span class="font-weight-bold">
                            <a :href="simulateUserUrl()">
                              {{ $t("admin.superadmin.charities.details.simulate_charity_owner") }}
                            </a>
                          </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          class="mx-5"
                          v-if="detailsToDisplay.isApplicationownerAdmin && detailsToDisplay.charityActive"
                        >
                          <a class="error--text" href="javascript:void(0)" @click="confirmationDialog = true">
                            {{ $t("admin.superadmin.charities.actions.charityDeactivate") }}
                          </a>
                        </v-list-item-subtitle>
                        <!--
                        <v-list-item-subtitle v-if="detailsToDisplay.paymentUrl" class="mx-5">
                          <span class="font-weight-bold">
                            <a :href="detailsToDisplay.paymentUrl">
                              {{ $t('admin.superadmin.charities.details.payment_link') }}
                            </a>
                          </span>
                        </v-list-item-subtitle>
-->
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="detailsDialog = false">
                {{ $t("admin.superadmin.charities.actions.cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmationDialog" max-width="400" persistent>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2 justify-center">
              {{ $t("admin.superadmin.charities.confirmation_title") }}
            </v-card-title>
            <v-card-text class="text-justify">
              {{ $t("admin.superadmin.charities.confirmation_text") }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="confirmationDialog = false">
                {{ $t("admin.superadmin.charities.actions.cancel") }}
              </v-btn>
              <v-btn color="primary" raised @click="deactivateCharity()">
                {{ $t("admin.superadmin.charities.actions.yes") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "../../../../util/debounce";
import YesNoIcon from "../components/yes-no-icon.vue";

export default {
  components: {
    YesNoIcon,
  },
  data() {
    return {
      search: "",
      pagination: {
        itemsPerPage: 10,
      },
      totalItems: 0,
      items: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.charities.list.logo"),
          value: "logo",
          sortable: false,
          width: "150px",
        },
        {
          text: this.$t("admin.superadmin.charities.list.billing_id"),
          value: "billingId",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.charities.list.name"),
          value: "charityProfile.frontDisplayName",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.charities.list.country"),
          value: "country",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.charities.list.charity_active"),
          value: "deactivated",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.charities.list.custom_tip"),
          value: "customTip",
          sortable: false,
        },
        { value: "actions", sortable: false },
      ],
      detailsDialog: false,
      detailsToDisplay: {
        charityProfile: {},
      },
      selectedCharity: null,
      selectedCharityOwner: null,
      confirmationDialog: false,
    };
  },
  mounted() {
    // this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/charities`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
        },
      });
      this.items = result.charities.rows;
      this.totalItems = result.charities.count;
      this.loading = false;
    },
    async showDetails(item) {
      this.selectedCharity = item;
      const result = await this.$axios.get(`/superadmin/charities/details/${item.id}`);
      result.country = this.$t(result.country.labelKey);
      this.detailsToDisplay = result;
      this.selectedCharityOwner = await this.$axios.get(`/superadmin/charities/charity/${item.id}/owner`);
      this.detailsDialog = true;
    },
    async deactivateCharity() {
      this.selectedCharity.deactivated = 1;
      this.selectedCharity.charityProfile.externalIndexation = 0;
      this.selectedCharity.charityProfile.internalIndexation = 0;

      await this.$axios.put(`/superadmin/charities/deactivate/${this.selectedCharity.id}`, this.selectedCharity);

      this.detailsDialog = false;
      this.confirmationDialog = false;
      this.getDataFromApi();
      this.$store.commit("SUCCESS", this.$t("admin.superadmin.charities.msg_deleted"));
    },
    simulateUserUrl() {
      if (this.selectedCharityOwner) {
        return `${this.$store.state.settings.fundraisingBaseUrl}/simulateuser?userId=${this.selectedCharityOwner.userProfile.idUser}`;
      }
    },
  },
};
</script>

<style>
.charity-list-action-buttons-field {
  min-width: 120px;
}
</style>
