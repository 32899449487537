<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.fundraising_pages.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.fundraising_pages.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu v-model="menu" :close-on-content-click="false" left>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{ $t("admin.superadmin.fundraising_pages.actions.selectDateRange") }}
              </v-btn>
            </template>
            <v-card>
              <PeriodPicker v-model="dateRange"></PeriodPicker>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">
                  {{ $t("admin.superadmin.fundraising_pages.actions.cancel") }}
                </v-btn>
                <v-btn color="primary" raised @click="getDataFromApi()">
                  {{ $t("admin.superadmin.fundraising_pages.actions.apply") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.title="props">
            <a target="_blank" :href="pagesAdress[items.indexOf(props.item)]">{{ props.item.title }}</a>
          </template>
          <template v-slot:item.details="props">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div class="d-inline" v-on="on">
                  <v-btn @click="showDetails(props.item)" class="mx-2" color="primary" fab raised small>
                    <v-icon dark>mdi-newspaper-variant</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("admin.superadmin.fundraising_pages.actions.details") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog v-model="detailsDialog" max-width="600" @click:outside="cleanDialogData">
          <v-card>
            <v-card-title v-if="!changeStatusDialog">
              {{ $t("admin.superadmin.fundraising_pages.actions.details") }}
            </v-card-title>
            <v-card-title v-else>
              {{ $t("admin.superadmin.fundraising_pages.details.change_status") }}
            </v-card-title>
            <v-container v-if="!changeStatusDialog">
              <v-row>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("admin.superadmin.fundraising_pages.details.page") }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.page_id") }}:
                          </span>
                          <span>{{ detailsToDisplay.id }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.page_title") }}:
                          </span>
                          <span>{{ detailsToDisplay.title }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.owner_name") }}:
                          </span>
                          <span>{{ detailsToDisplay.authorName }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.owner_email") }}:
                          </span>
                          <span
                            ><a :href="`mailto:${detailsToDisplay.email}`">{{ detailsToDisplay.email }}</a></span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.creation_date") }}:
                          </span>
                          <span>{{ detailsToDisplay.creationDate | date }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.closing_date") }}:
                          </span>
                          <span>{{ detailsToDisplay.endDate | date }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <a :href="simulateUserUrl(detailsToDisplay.fundraiserId)">
                            {{ $t("admin.superadmin.fundraising_pages.details.simulateFundraiser") }}
                          </a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("admin.superadmin.fundraising_pages.details.fundraising") }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.target") }}:
                          </span>
                          <span>{{ detailsToDisplay.targetAmount || "none" }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.online_donations") }}:
                          </span>
                          <span>{{ detailsToDisplay.onlineDonation }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.offline_donations") }}:
                          </span>
                          <span>{{ detailsToDisplay.offlineDonation }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.fundraising_pages.details.total_amount") }}:
                          </span>
                          <span>{{ detailsToDisplay.totalDonation }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-row>
                <v-col>
                  <v-checkbox
                    class="mx-5"
                    v-model="detailsToDisplay.dataCreation"
                    :label="$t('admin.superadmin.fundraising_pages.details.data_creation')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    class="mx-5"
                    v-model="detailsToDisplay.accessibility"
                    :label="$t('admin.superadmin.fundraising_pages.details.accessibility_desc')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form @submit.prevent="save" id="changeStatusForm" v-model="valid">
                    <v-menu
                      v-model="menuDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formattedDate"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :rules="[rules.futureDate]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="detailsToDisplay.endDate"
                        @input="menuDatePicker = false"
                        :min="minDate"
                        :first-day-of-week="1"
                        :locale="$i18n.locale == 'en' ? 'en' : 'fr'"
                      ></v-date-picker>
                    </v-menu>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  detailsDialog = false;
                  changeStatusDialog = false;
                "
              >
                {{ $t("admin.superadmin.fundraising_pages.details.cancel") }}
              </v-btn>
              <v-btn v-if="!changeStatusDialog" raised color="primary" type="button" @click="popUpStatusDialog">
                {{ $t("admin.superadmin.fundraising_pages.details.change_status") }}
              </v-btn>
              <v-btn v-else :disabled="!valid" raised color="primary" type="submit" form="changeStatusForm">
                {{ $t("admin.superadmin.fundraising_pages.details.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "../../../../util/debounce";
import PeriodPicker from "../components/period-picker";
const moment = require("moment");

export default {
  components: {
    PeriodPicker,
  },
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        itemsPerPage: 10,
      },
      totalItems: 0,
      items: [],
      pagesAdress: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.fundraising_pages.list.page_id"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.fundraising_pages.list.owner_user_id"),
          value: "idUser",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.fundraising_pages.list.owner_name"),
          value: "authorName",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.fundraising_pages.list.page_title"),
          value: "title",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.fundraising_pages.list.event"),
          value: "event.eventProfile[0].name",
          sortable: false,
        },
        { value: "details", sortable: false },
      ],
      detailsDialog: false,
      detailsToDisplay: {},
      editedFP: {},
      changeStatusDialog: false,
      menuDatePicker: false,
      minDate: moment().add(1, "day").format("YYYY-MM-DD"),
      rules: {
        futureDate: (value) =>
          moment(value, "DD/MM/YYYY").isAfter(moment(), "day") || this.$t("errors.validator.futureDate"),
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      menu: false,
    };
  },
  computed: {
    formattedDate: {
      get() {
        if (!this.detailsToDisplay.endDate) return null;
        return moment(this.detailsToDisplay.endDate).format("DD/MM/YYYY");
      },
      set(date) {
        const parsedDate = moment(date, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          this.detailsToDisplay.endDate = parsedDate;
        }
      },
    },
  },
  mounted() {
    // this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      this.menu = false;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/fundraising/pages`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
          from: this.dateRange.startDate,
          to: this.dateRange.endDate,
        },
      });
      this.items = result.fundraisingPages.rows;
      this.pagesAdress = result.pagesAdress;
      this.totalItems = result.fundraisingPages.count;
      this.loading = false;
    },
    async showDetails(item) {
      this.editedFP = item;
      const result = await this.$axios.get(`/superadmin/fundraising/page/details/${item.id}`);
      result.endDate = moment(result.endDate).format("YYYY-MM-DD");
      this.detailsDialog = true;
      this.detailsToDisplay = result;
    },
    async save() {
      this.detailsDialog = false;
      this.changeStatusDialog = false;
      this.editedFP.endDate = moment(this.detailsToDisplay.endDate).utc().format();
      this.editedFP.dataCreation = this.detailsToDisplay.dataCreation;
      this.editedFP.accessibility = this.detailsToDisplay.accessibility;

      await this.$axios.put(`/superadmin/fundraising/page/${this.editedFP.id}`, this.editedFP);

      this.$store.commit("SUCCESS", this.$t("admin.superadmin.fundraising_pages.actions.saved"));
    },
    popUpStatusDialog(e) {
      this.changeStatusDialog = true;
      e.preventDefault();
    },
    cleanDialogData() {
      this.detailsDialog = false;
      this.changeStatusDialog = false;
    },
    simulateUserUrl(id) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/simulateuser?userId=${id}`;
    },
  },
};
</script>

<style></style>
