<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.transactions.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.transactions.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu v-model="menu" :close-on-content-click="false" left>
            <template v-slot:activator="{ on }">
              <v-card flat>
                <v-card-title>
                  <v-btn color="primary" dark v-on="on">
                    {{ $t("admin.superadmin.transactions.actions.selectDateRange") }}
                  </v-btn>
                </v-card-title>
                <v-card-subtitle class="">
                  <div class="mt-2 text-center">
                    <span class="text-subtitle-1 font-weight-medium">{{
                      $t("admin.superadmin.transactions.actions.period")
                    }}</span>
                  </div>
                  <div class="text-caption text-center">
                    <span>{{ $t("admin.superadmin.transactions.actions.dateFrom") }}</span>
                    <span class="ml-2">{{ dateRange.startDate | date }}</span>
                    <span class="ml-2">{{ $t("admin.superadmin.transactions.actions.dateTo") }}</span>
                    <span class="ml-2">{{ dateRange.endDate | date }}</span>
                  </div>
                </v-card-subtitle>
              </v-card>
            </template>
            <v-card>
              <PeriodPicker v-model="dateRange"></PeriodPicker>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">
                  {{ $t("admin.superadmin.transactions.actions.cancel") }}
                </v-btn>
                <v-btn color="primary" raised @click="getDataFromApi()">
                  {{ $t("admin.superadmin.transactions.actions.apply") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.date="props">
            <span>{{ props.item.date | date }}</span>
          </template>
          <template v-slot:item.status="props">
            <v-tooltip v-if="props.item.status == 'VALIDATED'" left>
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-check-bold</v-icon>
              </template>
              <span>{{ $t("admin.superadmin.transactions.status.validated") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="props.item.status == 'NOT_PAYED'" left>
              <template v-slot:activator="{ on }">
                <v-icon color="warning" v-on="on">mdi-timer-sand</v-icon>
              </template>
              <span>{{ $t("admin.superadmin.transactions.status.not_payed") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="props.item.status == 'PAYMENT_FAILURE'" left>
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">mdi-alert-circle</v-icon>
              </template>
              <span>{{ $t("admin.superadmin.transactions.status.payment_failure") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="props.item.status == 'REFUNDED'" left>
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">mdi-cash-refund</v-icon>
              </template>
              <span>{{ $t("admin.superadmin.transactions.status.refunded") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.details="props">
            <div class="action-buttons-field">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <div class="d-inline" v-on="on">
                    <v-btn @click="showDetails(props.item)" class="mx-2" color="primary" fab raised small>
                      <v-icon dark>mdi-newspaper-variant</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("admin.superadmin.transactions.actions.details") }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="detailsDialog" max-width="500">
          <v-card>
            <v-card-title>
              {{ $t("admin.superadmin.transactions.actions.details") }}
            </v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.transactions.details.transaction_id") }}:
                          </span>
                          <span>{{ detailsToDisplay.id }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold">{{ $t("admin.superadmin.transactions.details.date") }}: </span>
                          <span>{{ detailsToDisplay.date | date }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.transactions.details.amount") }}:</span
                          >
                          <span>{{ detailsToDisplay.amount }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold">{{ $t("admin.superadmin.transactions.details.time") }}: </span>
                          <span>{{ detailsToDisplay.date | time }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.transactions.details.status") }}:
                          </span>
                          <span>{{ detailsToDisplay.status }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.transactions.details.emitter") }}:
                          </span>
                          <span>{{ detailsToDisplay.userName }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.transactions.details.recipient") }}:
                          </span>
                          <span>{{ detailsToDisplay.frontDisplayName }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="detailsToDisplay.frpUrl" class="mx-5">
                          <span class="font-weight-bold"
                            >{{ $t("admin.superadmin.transactions.details.fundraisingPage") }}:
                          </span>
                          <a target="_blank" :href="detailsToDisplay.frpUrl">{{ detailsToDisplay.frpName }}</a>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <a :href="simulateRecipientUrl(detailsToDisplay)">
                            {{ $t("admin.superadmin.transactions.details.simulateRecipient") }}
                          </a>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5" v-if="detailsToDisplay.idUser">
                          <a :href="simulateEmitterUrl(detailsToDisplay)">
                            {{ $t("admin.superadmin.transactions.details.simulateEmitter") }}
                          </a>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mx-5">
                          <a :href="downloadUrl(detailsToDisplay)">
                            {{ $t("admin.superadmin.transactions.details.download_receipt") }}
                          </a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="detailsDialog = false">
                {{ $t("admin.eventFundraising.fundraisers.email.cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "../../../../util/debounce";
import PeriodPicker from "../components/period-picker";
const moment = require("moment");

export default {
  components: {
    PeriodPicker,
  },
  data() {
    return {
      search: "",
      pagination: {
        itemsPerPage: 10,
      },
      totalItems: 0,
      items: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.transactions.list.transaction_id"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.transactions.list.transaction_date"),
          value: "date",
          sortable: true,
        },
        {
          text: this.$t("admin.superadmin.transactions.list.emitter"),
          value: "userName",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.transactions.list.recipient"),
          value: "frontDisplayName",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.transactions.list.amount"),
          value: "amount",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.transactions.list.status"),
          value: "status",
          sortable: false,
        },
        { value: "details", sortable: false },
      ],
      detailsDialog: false,
      detailsToDisplay: {},
      dateRange: {
        startDate: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      menu: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi, 700);
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      this.menu = false;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/transactions`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
          from: this.dateRange.startDate,
          to: this.dateRange.endDate,
        },
      });
      this.items = result.rows;
      this.totalItems = result.count;
      this.loading = false;
    },
    showDetails(item) {
      this.detailsDialog = true;
      this.detailsToDisplay = item;
    },
    simulateRecipientUrl(item) {
      return `/my/${item.idCharity}/applications`;
    },
    simulateEmitterUrl(item) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/simulateuser?userId=${item.idUser}`;
    },
    downloadUrl(item) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/payment-return/${item.transactionIdForPsp}/accepted/${item.paymentReturnToken}`;
    },
  },
};
</script>

<style></style>
