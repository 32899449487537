<template>
  <v-row justify="center">
    <v-col lg="10" xl="8">
      <v-card>
        <v-card-title>
          {{ $t("admin.charity.users.title") }}
          <v-spacer />
          <v-btn color="primary" v-on:click="createNew">{{ $t("admin.charity.users.add") }}</v-btn>
        </v-card-title>

        <v-card-subtitle class="text-caption">
          {{ $t("admin.charity.users.description") }}
        </v-card-subtitle>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="pagination"
            :server-items-length="totalItems"
            item-key="id"
          >
            <template v-slot:item.actions="props">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <div class="d-inline" v-on="on">
                    <v-btn
                      @click="removeUser(props.item)"
                      class="mr-1 px-0"
                      color="error"
                      raised
                      small
                      style="min-width: 41px"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("admin.charity.users.remove") }}</span>
              </v-tooltip>
              <v-btn class="px-0" small color="primary" @click="showEditForm(props.item)" style="min-width: 41px">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.admin="props">
              <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_ORGANIZATION_USERSUPPORT')"></YesNoIcon>
            </template>
            <template v-slot:item.fundraising="props">
              <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_ORGANIZATION_FUNDRAISING')"></YesNoIcon>
            </template>
            <template v-slot:item.donationforms="props">
              <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_ORGANIZATION_DONATIONFORMS')"></YesNoIcon>
            </template>
            <template v-slot:item.marketing="props">
              <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_ORGANIZATION_MARKETING')"></YesNoIcon>
            </template>
            <template v-slot:item.payment="props">
              <YesNoIcon :value="hasRole(props.item, 'ROLE_FUNDRAISING_ORGANIZATION_PAYMENT')"></YesNoIcon>
            </template>
          </v-data-table>
        </v-card-text>

        <v-dialog v-model="createUserDialog" max-width="60%" :width="$vuetify.breakpoint.mdAndUp ? '600px' : '900px'">
          <v-card>
            <v-card-title>{{ $t("admin.charity.users.add") }}</v-card-title>
            <v-container>
              <v-form @submit.prevent="saveUser" id="addUserForm" v-model="addUserFormValid">
                <v-text-field
                  class="px-3"
                  prepend-icon="mdi-account"
                  v-model="newUser.firstName"
                  :label="$t('admin.charity.users.firstName')"
                  :rules="[$v.required()]"
                ></v-text-field>
                <v-text-field
                  class="px-3"
                  prepend-icon="mdi-account"
                  v-model="newUser.lastName"
                  :label="$t('admin.charity.users.lastName')"
                  :rules="[$v.required()]"
                ></v-text-field>
                <v-text-field
                  class="px-3"
                  prepend-icon="mdi-email-outline"
                  v-model="newUser.login"
                  :label="$t('admin.charity.users.email')"
                  :rules="[$v.required(), $v.isEmail()]"
                ></v-text-field>
              </v-form>
            </v-container>

            <v-card-actions class="pb-5">
              <v-spacer></v-spacer>
              <v-btn @click="createUserDialog = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn raised type="submit" color="primary" form="addUserForm" :disabled="!addUserFormValid">
                {{ $t("submit") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog max-width="700px" v-model="updateDialog">
          <v-card>
            <v-form @submit.prevent="updateUser" ref="editForm">
              <input type="hidden" name="id" v-model="editedUser.id" />
              <v-card-title>
                <span class="headline">{{ $t("admin.superadmin.users.update.title") }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col> {{ $t("admin.superadmin.users.create.email") }} {{ editedUser.login }} </v-col>
                </v-row>
                <v-row>
                  <v-col> {{ $t("admin.superadmin.users.create.name") }} {{ editedUser.userProfile.userName }} </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-for="feature in toolkit.features"
                      :key="feature.id"
                      :label="feature.label"
                      autocomplete="off"
                      name="newUser.name"
                      required
                      v-model="editedUser.features"
                      :value="feature.id"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateDialog = false" color="grey" text>
                  {{ $t("prop.cancel") }}
                </v-btn>
                <v-btn color="primary" type="submit">
                  {{ $t("prop.update") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import YesNoIcon from "../components/yes-no-icon.vue";
export default {
  components: {
    YesNoIcon,
  },
  data() {
    return {
      loading: false,
      totalItems: 0,
      hasMore: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      headers: [
        { value: "UserContactInformation.firstName", text: this.$t("admin.charity.users.firstName"), sortable: false },
        { value: "UserContactInformation.lastName", text: this.$t("admin.charity.users.lastName"), sortable: false },
        { value: "login", text: this.$t("admin.charity.users.email"), sortable: false },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.organization.usersupport"),
          value: "admin",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.organization.fundraising"),
          value: "fundraising",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.organization.donationforms"),
          value: "donationforms",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.organization.marketing"),
          value: "marketing",
          sortable: false,
        },
        {
          text: this.$t("prop.toolkitdata.feature.fundraising.organization.payment"),
          value: "payment",
          sortable: false,
        },
        { value: "actions", sortable: false, width: 170 },
      ],
      newUser: {},
      createUserDialog: false,
      addUserFormValid: true,
      editedUser: {
        userProfile: {},
      },
      updateDialog: false,
      toolkit: {},
    };
  },
  mounted() {
    this.getDataFromApi();
    this.getToolkit();
  },
  methods: {
    async getDataFromApi() {
      const result = await this.$axios.get(`/charity/${this.$store.state.charity.id}/users`, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
        },
      });
      this.items = result.rows;
      this.totalItems = result.count;

      this.loading = false;
    },
    hasRole(user, role) {
      return (
        user.features.filter((val) => {
          return val.label == role;
        }).length > 0
      );
    },
    async getToolkit() {
      this.toolkit = await this.$axios.get("/common/toolkit");
      this.toolkit.features = this.toolkit.features.filter((r) => [4].indexOf(r.backofficeType) !== -1);
      this.toolkit.features.map((r) => (r.label = this.$t(r.propertiesKey)));
    },
    createNew() {
      this.newUser = {};
      this.createUserDialog = true;
    },
    async saveUser() {
      const result = await this.$axios.post(`/charity/${this.$store.state.charity.id}/user`, {
        params: {
          user: this.newUser,
        },
      });
      if (result) {
        this.createUserDialog = false;
        this.$store.commit("SUCCESS", this.$t("admin.charity.users.created"));
        this.getDataFromApi();
      } else {
        this.$store.commit("ERROR", this.$t("admin.charity.users.failed"));
      }
    },
    async removeUser(item) {
      if (!confirm(this.$t("admin.charity.users.confirm"))) return;
      const result = await this.$axios.delete(`/charity/${this.$store.state.charity.id}/user/${item.id}`, {});
      if (result) {
        this.$store.commit("SUCCESS", this.$t("admin.charity.users.deleted"));
        this.getDataFromApi();
      }
    },
    showEditForm(user) {
      this.updateDialog = true;
      let userData = {
        id: user.id,
        login: user.login,
        name: user.name,
        userProfile: user.userProfile,
        features: user.features.map((e) => {
          return e.id;
        }),
      };
      this.editedUser = userData;
    },
    async updateUser() {
      await this.$axios.put(`/charity/${this.$store.state.charity.id}/user/${this.editedUser.id}`, {
        params: {
          user: this.editedUser,
        },
      });
      this.$store.commit("SUCCESS", this.$t("admin.charity.users.updated"));
      this.updateDialog = false;
      this.getDataFromApi();
    },
  },
};
</script>

<style></style>
