<template>
  <div>
    <PageCard></PageCard>
    <v-list>
      <v-list-item
        :to="`/donationForm/charity/${$route.params.charityId}/forms/${$route.params.formId}/dashboard`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-chart-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/donationForm/charity/${$route.params.charityId}/forms/${$route.params.formId}/donors`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.donors") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-5" />
      <!--      <v-subheader>{{ $t("admin.menu.settings.title") }}</v-subheader> -->
      <v-list-item
        :to="`/donationForm/charity/${$route.params.charityId}/forms/${$route.params.formId}/settings`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.eventProfile") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/donationForm/charity/${$route.params.charityId}/forms/${$route.params.formId}/branding`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-palette</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.design") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/donationForm/charity/${$route.params.charityId}/forms/${$route.params.formId}/links`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-link-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.links") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="`/donationForm/charity/${$route.params.charityId}/forms/${$route.params.formId}/notifications`"
        ripple
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>mdi-bell-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.notifications") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PageCard from "../../_menu.context.selector.vue";

export default {
  data() {
    return {
      eventProfileVisible: false,
      groupsVisible: false,
    };
  },
  async mounted() {
    /* let event = await this.$axios.get(`/common/event/${this.$route.params.formId}`);
    this.eventProfileVisible = event.idOwningCharity == this.$store.state.charity.id;

    let result2 = await this.$axios.get(
      `/admin/${this.$store.state.charity.id}/donationForm/${this.$route.params.formId}/challengePage`
    );
    this.challengePage = result2.challengePage;
    this.groupsVisible = this.challengePage.enableGroupPages;*/
  },
  components: {
    PageCard,
  },
};
</script>
