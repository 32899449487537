<template>
  <div>
    <PageCard></PageCard>
    <v-list>
      <v-list-item :to="`/personal/charity/${$route.params.charityId}/dashboard`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-chart-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/personal/charity/${$route.params.charityId}/fundraisers`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.fundraisers") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item :to="`/personal/charity/${$route.params.charityId}/inmemoriam`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.inMemoriam") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider class="my-5" />
      <v-list-item :to="`/personal/charity/${$route.params.charityId}/settings/content`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-text-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.content") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/personal/charity/${$route.params.charityId}/branding`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-palette</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.design") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/personal/charity/${$route.params.charityId}/settings/coaching`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.coaching") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="`/personal/charity/${$route.params.charityId}/settings/notifications`" ripple color="primary">
        <v-list-item-icon>
          <v-icon>mdi-bell-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("admin.menu.settings.notifications") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PageCard from "../../_menu.context.selector.vue";

export default {
  components: {
    PageCard,
  },
};
</script>
