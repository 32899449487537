<template>
  <v-form v-model="valid">
    <v-menu
      :close-on-content-click="false"
      min-width="290px"
      offset-y
      ref="colorPickerMenu"
      transition="scale-transition"
      v-model="colorPickerMenu"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :disabled="disabled"
          :label="label"
          :rules="validRule"
          @focus="$event.target.select()"
          v-bind:value="value"
          v-on:keyup="handleKeyup"
        >
          <v-icon v-if="showPicker" v-on="on" slot="append"> mdi-eyedropper </v-icon>
          <v-icon :color="value" slot="prepend"> mdi-format-color-fill </v-icon>
        </v-text-field>
      </template>
      <v-color-picker :disabled="disabled" no-title scrollable v-bind:value="value" v-on:input="$emit('input', $event)">
      </v-color-picker>
    </v-menu>
  </v-form>
</template>

<script>
export default {
  name: "ColorPicker",
  data() {
    return {
      colorPickerColor: this.value,
      colorPickerMenu: null,
      defaultRule: [this.$v.isHexColor()],
      valid: false,
    };
  },
  props: {
    value: String,
    selectedColor: String,
    label: String,
    disabled: Boolean,
    rules: Array,
    showPicker: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleKeyup: function (ev) {
      if (ev.target.value.length == 7) {
        this.$emit("input", ev.target.value);
      }
    },
  },
  watch: {
    valid: {
      handler() {
        this.$emit("update:valid", this.valid);
      },
    },
  },
  computed: {
    validRule() {
      let result = [];
      result = result.concat(this.defaultRule);
      if (this.rules) {
        result = result.concat(this.rules);
      }
      return result;
    },
  },
};
</script>
