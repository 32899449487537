<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.dashboard.title") }}
        </v-card-title>
        <v-row>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-content v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')">
                  <v-list-item-title> {{ $t("admin.superadmin.dashboard.user_support.title") }}: </v-list-item-title>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'/superadmin/transactions'">
                          {{ $t("admin.superadmin.dashboard.user_support.transaction_title") }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("admin.superadmin.dashboard.user_support.transaction_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'/superadmin/fundraisingPages'">
                          {{ $t("admin.superadmin.dashboard.user_support.fundraising_title") }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("admin.superadmin.dashboard.user_support.fundraising_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'/superadmin/users'">
                          {{ $t("admin.superadmin.dashboard.user_support.user_title") }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("admin.superadmin.dashboard.user_support.user_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> {{ $t("admin.superadmin.dashboard.user_support.other") }}: </v-list-item-title>
                  <v-list-item two-line v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')">
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'/superadmin/events'">
                          {{ $t("admin.superadmin.dashboard.user_support.events_title") }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("admin.superadmin.dashboard.user_support.events_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_FUNDRAISING')">
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'/superadmin/charities'">
                          {{ $t("admin.superadmin.dashboard.user_support.charities_title") }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("admin.superadmin.dashboard.user_support.charities_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="hasRole('ROLE_FUNDRAISING_APPLICATIONOWNER_ADMIN')">
                    <v-list-item-content>
                      <v-list-item-title>
                        <a :href="'/superadmin/superadmins'">
                          {{ $t("admin.superadmin.dashboard.user_support.admin_title") }}
                        </a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("admin.superadmin.dashboard.user_support.admin_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    hasRole(role) {
      return (
        this.$store.state.me.userRoles.indexOf(role) !== -1 ||
        this.$store.state.me.userRoles.indexOf("ROLE_FUNDRAISING_APPLICATIONOWNER_ADMIN") !== -1
      );
    },
  },
};
</script>

<style></style>
