<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.donationForm.donors.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.donationForm.donors.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu v-model="menu" :close-on-content-click="false" left>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{ $t("admin.donationForm.donors.actions.export") }}
              </v-btn>
            </template>
            <v-card>
              <PeriodPicker v-model="dateRange"></PeriodPicker>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">
                  {{ $t("admin.donationForm.donors.actions.cancel") }}
                </v-btn>
                <v-btn color="primary" raised @click="exportDownload()">
                  {{ $t("admin.donationForm.donors.actions.export") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.donationForm.donors.description") }}
        </v-card-subtitle>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.lastName="props">
            {{ `${props.item.firstName} ${props.item.lastName}` }}
          </template>
          <template v-slot:item.creationDate="props">
            {{ props.item.creationDate | date }}
          </template>
          <template v-slot:item.title="props">
            <a target="_blank" :href="props.item.frpUrl">{{ props.item.title }}</a>
          </template>
          <template v-slot:item.risedOnline="props">
            {{ props.item.amount | amount("EUR") }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
const fileDownload = require("js-file-download");
const moment = require("moment");

import PeriodPicker from "../components/period-picker";
import debounce from "../../../../util/debounce";

export default {
  components: {
    PeriodPicker,
  },
  data() {
    return {
      valid: false,
      totalItems: 0,
      items: [],
      loading: true,
      detailProps: {},
      menu: false,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      pagination: {
        itemsPerPage: 10,
      },
      sortable: false,
      search: "",
      headers: [
        { text: this.$t("admin.donationForm.donors.list.name"), value: "lastName", sortable: false },
        {
          text: this.$t("admin.donationForm.donors.list.creationDate"),
          value: "creationDate",
          sortable: false,
        },
        {
          text: this.$t("admin.donationForm.donors.list.raisedAmount"),
          value: "risedOnline",
          sortable: false,
        },
        { value: "id", sortable: false },
      ],
      exportData: {
        from: moment().startOf("month"),
        to: null,
      },
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
      deep: true,
    },
  },
  methods: {
    async exportDownload() {
      this.$axios({
        url: `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/excelReport/${this.dateRange.startDate}/${this.dateRange.endDate}`,
        method: "GET",
        responseType: "blob",
        params: {
          search: this.search || undefined,
        },
      }).then((response) => {
        fileDownload(
          response,
          `${this.$i18n.t("admin.donationForm.donors.exportFileName")}${moment().format("YYYY-MM-DD")}.xls`
        );
      });
    },
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(
        `/donationForms/${this.$store.state.charity.id}/forms/${this.$route.params.formId}/donors`,
        {
          params: {
            page: page - 1,
            size: itemsPerPage,
            sortBy: sortBy.join(","),
            sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
            search: this.search || undefined,
          },
        }
      );
      this.items = result.rows;
      this.totalItems = result.count;
      this.loading = false;
    },
  },
};
</script>
<style>
.ql-editor {
  min-height: 150px;
  max-height: 300px;
}
.reportrange-text {
  border: none !important;
}
.v-data-footer__select {
  display: none;
}
</style>
