var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xl":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("admin.personalFundraising.settings.contentPage.title"))+" ")]),_c('v-card-subtitle',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("admin.personalFundraising.settings.contentPage.titleDescription"))+" ")]),_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',[_c('quill-editor',{attrs:{"options":{
                  modules: {
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                      [{ align: ['', 'center', 'right'] }],
                      ['link'],
                      ['clean'] ],
                  },
                  placeholder: _vm.$t('admin.personalFundraising.settings.contentPage.description'),
                }},model:{value:(_vm.charityProfile.content),callback:function ($$v) {_vm.$set(_vm.charityProfile, "content", $$v)},expression:"charityProfile.content"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"raised":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("admin.users.edit.save")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }