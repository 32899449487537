<template>
  <v-app ref="app">
    <v-snackbar
      v-for="(message, index) in $store.state.messages"
      :key="index"
      :color="message.type || 'info'"
      value="message.message"
      top
      :timeout="message.timeout || 7000"
      class="mt-5"
    >
      {{ message.key ? $t(message.key, message.params) : message.message }}
      <v-icon color="white" v-if="message.reactionAddons">{{ message.reactionAddons.type }}</v-icon>
    </v-snackbar>
    <v-fade-transition mode="out-in">
      <v-overlay :value="$store.state.loading" opacity="0.7">
        <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <v-footer
      dark
      absolute
      app
      color="#A6ADB4"
      class="caption white--text pt-2"
      :height="$vuetify.breakpoint.mdAndUp ? '60px' : '200px'"
    >
      <v-row no-gutters>
        <v-col cols="12" md="auto" class="text-center mx-3 text-md-left"
          ><img src="/assets/alvarum-white.svg" height="20px"
        /></v-col>
        <v-col cols="12" md="auto" class="text-center mx-3 text-md-right"
          >&#169; {{ new Date().getFullYear() }}
          <a href="https://www.alvarum.com" class="white--text font-weight-bold text-decoration-none">Alvarum</a></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="12" md="auto" class="text-center mx-3"
          ><a :href="$t('footer.help.link')" class="white--text text-decoration-none">{{
            $t("footer.help.text")
          }}</a></v-col
        >
        <v-col cols="12" md="auto" class="text-center mx-3"
          ><a :href="$t('footer.terms_and_conditions.link')" class="white--text text-decoration-none">{{
            $t("footer.terms_and_conditions.text")
          }}</a></v-col
        >
        <v-col cols="12" md="auto" class="text-center mx-3"
          ><a :href="$t('footer.privacy.link')" class="white--text text-decoration-none">{{
            $t("footer.privacy.text")
          }}</a></v-col
        >
        <v-col cols="12" md="auto" class="text-center mx-3"
          ><a :href="$t('footer.imprint.link')" class="white--text text-decoration-none">{{
            $t("footer.imprint.text")
          }}</a></v-col
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-footer>
    <HelpChat />
  </v-app>
</template>

<script>
import HelpChat from "./common/_helpChat";

export default {
  name: "App",
  components: {
    HelpChat,
  },
};
</script>

<style>
html,
body {
  min-height: 100vh;
}

.show-only-on-hover {
  opacity: 0;
}

td:hover .show-only-on-hover {
  opacity: 1;
}

#app {
  background: #ebeded;
  min-height: 100vh;
}

.v-application--wrap {
  min-height: 100% !important;
  min-height: -webkit-fill-available !important;
}

.v-navigation-drawer--is-mobile {
  height: 100% !important;
  height: -webkit-fill-available !important;
}
</style>
