<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.groups_list.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.groups_list.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-card></v-card>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.name="props">
            <a target="_blank" :href="props.item.groupUrl">{{ props.item.name }}</a>
          </template>
          <template v-slot:item.simulate="props">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div class="d-inline" v-on="on">
                  <v-btn :href="simulateOwner(props.item)" class="mx-2" color="primary" fab raised small>
                    <v-icon dark>mdi-account-arrow-right</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("admin.superadmin.groups_list.actions.simulate") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "../../../../util/debounce";

export default {
  data() {
    return {
      search: "",
      pagination: {
        itemsPerPage: 20,
      },
      totalItems: 0,
      items: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.groups_list.list.group_id"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.groups_list.list.owner"),
          value: "user.userProfile.userName",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.groups_list.list.name"),
          value: "name",
          sortable: false,
        },
        { value: "simulate", sortable: false },
      ],
    };
  },
  mounted() {
    // this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/groups/list`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
        },
      });
      this.items = result.rows;
      this.totalItems = result.count;
      this.loading = false;
    },
    simulateOwner(group) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/simulateuser?userId=${group.user.id}`;
    },
  },
};
</script>

<style></style>
