<template>
  <v-dialog v-model="opened" persistent max-width="700">
    <v-card>
      <v-card-title>
        {{ $t("admin.eventFundraising.fundraisers.email.title") }}
      </v-card-title>
      <v-form v-model="emailFormValid" @submit.prevent="sendEmail()" class="mx-6">
        <v-container fluid grid-list-lg v-if="mutableEmailData">
          <v-row>
            <v-col>
              <v-text-field
                v-model="mutableEmailData.from_name"
                :label="$t('admin.eventFundraising.fundraisers.email.senderName')"
                :rules="[$v.required()]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                prepend-icon="mdi-email-outline"
                v-model="mutableEmailData.from_email"
                :label="$t('admin.eventFundraising.fundraisers.email.senderEmail')"
                :rules="[$v.required(), $v.isEmail()]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="mutableEmailData.to[0]['name']"
                :label="$t('admin.eventFundraising.fundraisers.email.recipients')"
                :disabled="true"
              ></v-text-field>
              <v-text-field
                v-model="mutableEmailData.subject"
                :label="$t('admin.eventFundraising.fundraisers.email.subject')"
                :rules="[$v.required()]"
              ></v-text-field>
              <quill-editor
                v-model="mutableEmailData.html"
                :options="{
                  modules: {
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                      [{ align: ['', 'center', 'right'] }],
                      ['link'],
                      ['clean'],
                    ],
                  },
                  placeholder: $t('admin.eventFundraising.fundraisers.email.placeholder'),
                }"
              />

              <v-checkbox
                v-model="mutableEmailData.sendCopy"
                :label="$t('admin.eventFundraising.fundraisers.email.sendCopy')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="opened = false">
            {{ $t("admin.eventFundraising.fundraisers.email.cancel") }}
          </v-btn>
          <v-btn raised color="primary" type="submit" :disabled="!(emailFormValid && !!mutableEmailData.html)">
            {{ $t("admin.eventFundraising.fundraisers.email.submit") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      opened: false,
      emailFormValid: false,
      mutableEmailData: null,
    };
  },
  methods: {
    async sendEmail() {
      await this.$axios.post(`/admin/${this.$store.state.charity.id}/sendEmail`, this.mutableEmailData);
      this.opened = false;
      this.$store.commit("SUCCESS", this.$t("admin.eventFundraising.fundraisers.email.sentMessage"));
    },
    open(item) {
      return new Promise((resolve) => {
        this.opened = true;
        this.mutableEmailData = {
          from_name: this.$store.state.me.userName,
          from_email: this.$store.state.me.login,
          to: [{ email: item.email, name: item.firstName + " " + item.lastName }],
        };
        this.$once("close", (result) => {
          resolve(result);
          this.opened = false;
        });
      });
    },
  },
};
</script>
