<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.marketingExport.fundraisers.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.marketingExport.fundraisers.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu v-model="menu" :close-on-content-click="false" left>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{ $t("admin.marketingExport.fundraisers.actions.export") }}
              </v-btn>
            </template>
            <v-card>
              <PeriodPicker v-model="dateRange"></PeriodPicker>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">
                  {{ $t("admin.marketingExport.fundraisers.actions.cancel") }}
                </v-btn>
                <v-btn color="primary" raised @click="exportDownload()">
                  {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.marketingExport.fundraisers.description") }}
        </v-card-subtitle>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.imageUrl="props">
            <v-img :src="props.item.imageUrl" height="30px" width="30px" contain></v-img>
          </template>
          <template v-slot:item.lastName="props">
            <div class="font-weight-medium font-italic" v-if="props.item.email == 'registration@alvarum.com'">
              {{ $t("admin.marketingExport.fundraisers.actions.companyFundraising") }}
            </div>
            <div v-else>{{ props.item.firstName }} {{ props.item.lastName }}</div>
          </template>
          <template v-slot:item.creationDate="props">
            {{ props.item.creationDate | date }}
          </template>
          <template v-slot:item.title="props">
            <a target="_blank" :href="props.item.actionHomeUrl">{{ props.item.title }}</a>
          </template>
          <template v-slot:item.groupName="props">
            <a target="_blank" :href="props.item.groupUrl">{{ props.item.groupName }}</a>
          </template>
          <template v-slot:item.risedOnline="props">
            {{ (props.item.risedOnline + props.item.risedOffline) | amount("EUR") }}
          </template>
          <template v-slot:item.id="props">
            <div class="action-buttons-field">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <div class="d-inline" v-on="on">
                    <v-btn
                      @click="fundraiserEmail(props.item)"
                      class="mx-2"
                      color="primary"
                      dark
                      fab
                      raised
                      small
                      :disabled="props.item.email == 'registration@alvarum.com'"
                    >
                      <v-icon dark>mdi-email</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span v-if="props.item.email == 'registration@alvarum.com'">{{
                  $t("admin.marketingExport.fundraisers.actions.disabledNote")
                }}</span>
                <span v-else>{{ $t("admin.marketingExport.fundraisers.actions.email") }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="fundraiserDetails(props.item)"
                    class="mx-2"
                    color="primary"
                    dark
                    fab
                    raised
                    v-on="on"
                    small
                  >
                    <v-icon dark>mdi-newspaper-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("admin.marketingExport.fundraisers.actions.details") }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <FundraiserDetails v-model="detailProps" ref="detailDialog" v-bind:urlPrefix="'marketing'" />
        <send-email-dialog ref="dialog" />
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
const fileDownload = require("js-file-download");

import FundraiserDetails from "../components/fundraiser-details";
import PeriodPicker from "../components/period-picker";
import debounce from "../../../../util/debounce";
import sendEmailDialog from "../components/sendEmailDialog";

export default {
  components: {
    FundraiserDetails,
    PeriodPicker,
    sendEmailDialog,
  },
  data() {
    return {
      valid: false,
      totalItems: 0,
      items: [],
      loading: true,
      detailsDialog: false,
      offlineDonation: {},
      detailProps: {},
      menu: false,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      pagination: {
        itemsPerPage: 10,
      },
      sortable: false,
      search: "",
      headers: [
        { value: "imageUrl", sortable: false },
        { text: this.$t("admin.marketingExport.fundraisers.list.name"), value: "lastName", sortable: false },
        { text: this.$t("admin.marketingExport.fundraisers.list.fundraisingPage"), value: "title", sortable: false },
        {
          text: this.$t("admin.marketingExport.fundraisers.list.creationDate"),
          value: "creationDate",
          sortable: false,
        },
        {
          text: this.$t("admin.marketingExport.fundraisers.list.raisedAmount"),
          value: "risedOnline",
          sortable: false,
        },
        { value: "id", sortable: false },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
      deep: true,
    },
  },
  methods: {
    async fundraiserDetails(item) {
      this.detailsDialog = true;
      this.detailProps = item;
      this.$refs.detailDialog.show(item.idFundraisingPage);
    },
    fundraiserEmail(item) {
      this.detailProps = item;
      this.$refs.dialog.open(item);
    },
    async exportDownload() {
      this.$axios({
        url: `/marketing/${this.$store.state.charity.id}/fundraisers/excelReport/${this.dateRange.startDate}/${this.dateRange.endDate}`,
        method: "GET",
        responseType: "blob",
        params: {
          search: this.search || undefined,
        },
      }).then((response) => {
        fileDownload(response, `${this.$i18n.t("admin.marketingExport.fundraisers.exportFileName")}.xls`);
      });
      this.menu = false;
    },
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/marketing/${this.$store.state.charity.id}/fundraisers`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
        },
      });
      this.items = result.rows;
      this.totalItems = result.count;
      this.loading = false;
    },
  },
};
</script>
<style>
.ql-editor {
  min-height: 150px;
  max-height: 300px;
}
.v-data-footer__select {
  display: none;
}
.action-buttons-field {
  min-width: 115px;
}
</style>
