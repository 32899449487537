<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.marketingExport.customExport.title") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-subtitle class="text-caption">
          {{ $t("admin.marketingExport.customExport.titleDescription") }}
        </v-card-subtitle>
        <v-card-content>
          <v-row>
            <v-col lg="8" xl="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("admin.marketingExport.fundraisers.title") }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-divider light class="mt-3"></v-divider>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-menu v-model="fundraisersExportMenu" :close-on-content-click="false" left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">
                        {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                        <v-icon class="ml-2"> mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <PeriodPicker v-model="dateRange"></PeriodPicker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="fundraisersExportMenu = false">
                          {{ $t("admin.marketingExport.fundraisers.actions.cancel") }}
                        </v-btn>
                        <v-btn color="primary" raised @click="exportFundraisersDownload()">
                          {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("admin.marketingExport.donors.title") }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-divider light class="mt-3"></v-divider>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-menu v-model="donorsExportMenu" :close-on-content-click="false" left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">
                        {{ $t("admin.marketingExport.donors.actions.export") }}
                        <v-icon class="ml-2"> mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <PeriodPicker v-model="dateRange"></PeriodPicker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="donorsExportMenu = false">
                          {{ $t("admin.marketingExport.donors.actions.cancel") }}
                        </v-btn>
                        <v-btn color="primary" raised @click="exportDonorsDownload()">
                          {{ $t("admin.marketingExport.donors.actions.export") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="8" xl="6">
              <v-list>
                <template v-if="!items || !items.length">
                  <v-card-text>
                    {{ $t("admin.marketingExport.customExport.noItems") }}
                    <a href="https://alvarum.desk.com/customer/en/portal/emails/new">{{
                      $t("admin.marketingExport.customExport.contactUs")
                    }}</a>
                  </v-card-text>
                </template>
                <template v-else>
                  <v-card-text>
                    {{ $t("admin.marketingExport.customExport.description") }}
                  </v-card-text>
                </template>
                <v-list-item v-for="(item, index) in items" :key="item.exportType">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-divider light class="mt-3"></v-divider>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-menu v-model="additionalExportMenus[index]" :close-on-content-click="false" left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">
                        {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                        <v-icon class="ml-2"> mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <PeriodPicker v-model="dateRange"></PeriodPicker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="additionalExportMenus.splice(index, 1, false)">
                          {{ $t("admin.marketingExport.fundraisers.actions.cancel") }}
                        </v-btn>
                        <v-btn color="primary" raised @click="exportDownload(item)">
                          {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-list-item>

                <v-list-item v-if="$store.state.charity.id == 71">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("admin.marketingExport.customExport.csvReintegrationpurposes71")
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-divider light class="mt-3"></v-divider>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-menu v-model="reintegrationPurposes71Menu" :close-on-content-click="false" left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">
                        {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                        <v-icon class="ml-2"> mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <PeriodPicker v-model="dateRange"></PeriodPicker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="reintegrationPurposes71Menu = false">
                          {{ $t("admin.marketingExport.fundraisers.actions.cancel") }}
                        </v-btn>
                        <v-btn color="primary" raised @click="exportDownloadReintegrationPurposes71()">
                          {{ $t("admin.marketingExport.fundraisers.actions.export") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-content>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
const fileDownload = require("js-file-download");
const moment = require("moment");
import PeriodPicker from "../components/period-picker";

export default {
  components: {
    PeriodPicker,
  },
  data() {
    return {
      items: null,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      fundraisersExportMenu: false,
      donorsExportMenu: false,
      reintegrationPurposes71Menu: false,
      additionalExportMenus: [],
    };
  },
  async mounted() {
    await this.getDataFromApi();
  },

  computed: {
    dateRangePickerLocales() {
      let locales = this.$t("prop.daterangepicker");
      locales.firstDay = 1;
      return locales;
    },
  },

  methods: {
    async exportDownload(item) {
      this.$axios({
        url: `/marketing/${this.$store.state.charity.id}/${item.exportType.toLowerCase()}-custom-export/${
          this.dateRange.startDate
        }/${this.dateRange.endDate}`,
        params: {
          id: item.id,
        },
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        fileDownload(
          response,
          `${this.$i18n.t(`admin.marketingExport.customExport.${item.exportType.toLowerCase()}.exportFileName`)}.xls`
        );
      });
    },
    async exportDownloadReintegrationPurposes71() {
      this.$axios({
        url: `/marketing/${this.$store.state.charity.id}/reintegration-custom-export/${this.dateRange.startDate}/${this.dateRange.endDate}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        let fromDate = new Date(this.dateRange.startDate);
        let monthString = (fromDate.getMonth() + 1).toString().padStart(2, "0");
        let fileName = `Dons-${fromDate.getFullYear()}${monthString}-71.csv`;
        fileDownload(response, fileName);
      });
    },
    async exportFundraisersDownload() {
      this.$axios({
        url: `/marketing/${this.$store.state.charity.id}/fundraisers/excelReport/${this.dateRange.startDate}/${this.dateRange.endDate}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        this.fundraisersExportMenu = false;
        fileDownload(response, `${this.$i18n.t("admin.marketingExport.fundraisers.exportFileName")}.xls`);
      });
      this.menu = false;
    },
    async exportDonorsDownload() {
      this.$axios({
        url: `/marketing/${this.$store.state.charity.id}/donors/excelReport/${this.dateRange.startDate}/${this.dateRange.endDate}`,
        method: "GET",
        responseType: "blob",
        params: {
          search: this.search || undefined,
        },
      }).then((response) => {
        this.donorsExportMenu = false;
        fileDownload(
          response,
          `${this.$i18n.t("admin.marketingExport.donors.exportFileName")}${moment().format("YYYY-MM-DD")}.xls`
        );
      });
    },
    async getDataFromApi() {
      const result = await this.$axios.get(`/marketing/${this.$store.state.charity.id}/custom-export`);
      this.items = result;
      this.additionalExportMenus = Array(this.items.length).fill(false);
    },
  },
};
</script>
<style scoped>
.reportrange-text {
  border: none !important;
}
</style>
