<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-card>
        <v-card-title>
          {{ $t("admin.superadmin.events.title") }}
          <v-spacer></v-spacer>
          <v-text-field
            :label="$t('admin.superadmin.events.actions.search')"
            append-icon="mdi-magnify"
            hide-details
            single-line
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark v-on="on" :href="`${$store.state.settings.fundraisingBaseUrl}/createAnEvent`">
            {{ $t("admin.superadmin.events.actions.create") }}
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:item.name="props">
            {{ props.item.eventProfile[0].name }}
          </template>
          <template v-slot:item.internalIndexation="props">
            <YesNoIcon :value="props.item.eventProfile[0].internalIndexation"></YesNoIcon>
          </template>
          <template v-slot:item.fundraisingEvent="props">
            <YesNoIcon :value="props.item.fundraisingEvent"></YesNoIcon>
          </template>
          <template v-slot:item.simulate="props">
            <a :href="viewProfileUrl(props.item)">
              {{ $t("admin.superadmin.events.actions.profile") }}
            </a>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import debounce from "../../../../util/debounce";
import YesNoIcon from "../components/yes-no-icon.vue";
export default {
  components: {
    YesNoIcon,
  },
  data() {
    return {
      search: "",
      pagination: {
        itemsPerPage: 10,
      },
      totalItems: 0,
      items: [],
      loading: true,
      headers: [
        {
          text: this.$t("admin.superadmin.events.list.event_id"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.events.list.name"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.events.list.fundraisingEvent"),
          value: "fundraisingEvent",
          sortable: false,
        },
        {
          text: this.$t("admin.superadmin.events.list.internalIndexation"),
          value: "internalIndexation",
          sortable: false,
        },
        { value: "simulate", sortable: false },
      ],
    };
  },
  mounted() {
    // this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.pagination.page = 1;
        debounce(this.getDataFromApi);
      },
    },
  },
  methods: {
    async getDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/superadmin/events`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
          sortBy: sortBy.join(","),
          sortOrder: sortDesc.map((s) => (s ? "desc" : "asc")).join(","),
          search: this.search || undefined,
        },
      });
      this.items = result.events.rows;
      this.totalItems = result.events.count;
      this.loading = false;
    },
    viewProfileUrl(item) {
      return `${this.$store.state.settings.fundraisingBaseUrl}/${item.eventProfile[0].urlPart.url}/alvarum`;
    },
  },
};
</script>

<style></style>
